import { ACTIONS } from '../constants/actions';

export default function userReducer(user, { type, payload = null }) {
  switch (type) {
    case ACTIONS.USER.USER_DETAILS_SET:
      return {
        ...user,
        ...payload,
      };
    case ACTIONS.USER.USER_BALANCE:
      return {
        ...user,
        ...payload,
      };
    case ACTIONS.USER.USER_LOGOUT:
      return undefined;
    case ACTIONS.USER.SPIN_CHANCE:
      return {
        ...user,
        ...payload,
      };
    case ACTIONS.USER.ISEMAIL_OTP_ACTIVE:
      return { ...user, isEmailOTPActive: payload };
    default:
      return user;
  }
}
