import { ACTIONS } from '../constants/actions';

export default function countdownReducer(countdown, { type, payload = null }) {
  switch (type) {
    case ACTIONS.COUNTDOWN.VERIFY_COUNTDOWN_TIMER:
      return { ...countdown, verifyCountdownTimer: payload };
    case ACTIONS.COUNTDOWN.REMOVED_TIMER:
      return { ...countdown, removeTimer: payload };
    case ACTIONS.COUNTDOWN.TIMER:
      return { ...countdown, timer: payload };
    case ACTIONS.COUNTDOWN.IS_COUNTDOWN:
      return { ...countdown, isCountdown: payload };
    default:
      return countdown;
  }
}
