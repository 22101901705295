import useAxios from 'axios-hooks';

import { API } from '../common/constants/api';

const { HOST, APIBASE, SHORTCUT } = API;

export function useGetShortCutList() {
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${SHORTCUT.GET_SHORTCUT}`,
  });

  return [{ data, loading, error }];
}

export function useGetShortcutCacheVersion() {
  const [{ data, loading, error }, getShortcut] = useAxios(
    {
      url: `${HOST}${APIBASE}${SHORTCUT.SHORTCUT_CACHE_VERSION}`,
    },
    { manual: true },
  );

  return [{ data, loading, error }, getShortcut];
}
