import { ACTIONS } from '../constants/actions';

/**
 * DAILY LOGIN POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowDailyLoginBonusPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.DAILYLOGINBONUS.POPUP,
  });

/**
 * DAILY LOGIN FLOAT BUTTON ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setFromDailyLoginFloatBtn = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.DAILYLOGINBONUS.FLOATBUTTON,
  });

/**
 * DAILY WEEKLY BONUS POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowDailyWeeklyBonusPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.DAILYWEEKLYBONUS.POPUP,
  });

/**
 * DAILY WEEKLY BONUS FLOAT BUTTON ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setFromDailyWeeklyFloatBtn = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.DAILYWEEKLYBONUS.FLOATBUTTON,
  });

/**
 * DEPOSIT APP BONUS POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowDepositAppBonusPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.POPUP.DEPOSITAPPBONUS_POPUP,
  });

/**
 * DEPOSIT APP BONUS POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setFromDepositAppBonusFloatBtn = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.POPUP.DEPOSITAPPBONUS_FLOATBUTTON,
  });

/**
 * HOME CURRENT POP UP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setCurrentPopUpOrder = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.HOME.CURRENTPOPUPORDER,
  });

/**
 * HOME ACTIVE POP UP LIST ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setActivePopUpList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.HOME.ACTIVEPOPUPLIST,
  });

/**
 * REFERRAL WHEEL BONUS POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowReferralBonusPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.REFERRAL.POPUP,
  });

/**
 * REFERRAL WHEEL BONUS FLOAT ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setFromReferralBonusFloatBtn = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.REFERRAL.FLOATBUTTON,
  });

/**
 * REFERRAL FIRST SPIN BONUS MODAL FLOAT ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowFirstSpinBonusModalOpen = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.REFERRAL.FIRSTSPINMODALOPEN,
  });

/**
 *WELCOME BONUS POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowWelcomeBonusPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.HOME.WELCOMEPOPUP,
  });

/**
 *MEMBER DEPOSIT BONUS POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowMemberDepositBonusPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.HOME.MEMBERDEPOSITPOPUP,
  });

/**
 *SYSTEM ANNOUNCEMENT POPUP ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setShowSysAnnouncementPopUp = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.POPUP.SYSANNOUNCEMENTPOPUP,
  });

/**
 *SYSTEM ANNOUNCEMENT LISTS ACTION
 * @param {Function} dispatch Action dispatcher from store
 */
export const setSysAnnouncementList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.POPUP.SYSANNOUNCEMENTLIST,
  });
