import { useEffect } from 'react';
import axios from 'axios';
import useAxios from 'axios-hooks';

//common
import {
  deleteAccessToken,
  setAccessToken,
  getAccessToken,
  deleteExpiredTime,
  setRefreshToken,
  getRefreshToken,
  deleteRefreshToken,
} from '../common/helpers/localStorage';
import { METHODS } from '../common/constants/keys';
import { API } from '../common/constants/api';
import { setUserDetails, userLogout } from '../common/reducer-actions/userActions';
import { switchTheme } from '../common/helpers/helper';

//store
import { useDispatch } from '../store/StateProvider';

const { HOST, APIBASE, ACCOUNT, AFFILIATE, BASESETTING } = API;

//for refresh token used
export function useUserPing() {
  const [{ data, loading, error }, ping] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.PING}`,
      method: METHODS.GET,
    },
    { manual: true },
  );
  return [{ data, loading, error }, ping];
}

export function useUserSessionActive(userId) {
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.SESSION_ACTIVE}/${userId}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, refetch];
}

export function useUserLogin() {
  const dispatch = useDispatch();
  const [{ data, loading, error }, login] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.LOGIN}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  useEffect(() => {
    if (data) {
      switchTheme(data?.theme);
      setAccessToken(data.jwtToken);
      setRefreshToken(data.refreshToken);
      setUserDetails(dispatch, data);
    }
  }, [data, dispatch]);

  return [{ data, loading, error }, login];
}

export function useThirdPartyLogin() {
  const dispatch = useDispatch();
  const [{ data, loading, error }, login] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.THIRD_PARTY_LOGIN}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  useEffect(() => {
    if (data) {
      setAccessToken(data.jwtToken);
      setRefreshToken(data.refreshToken);
      setUserDetails(dispatch, data);
    }
  }, [data, dispatch]);

  return [{ data, loading, error }, login];
}

export function useCheckThirdPartyRegistered() {
  const [{ data, loading, error }, fetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.CHECK_THIRD_PARTY_REGISTERED}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, fetch];
}

export function useThirdPartyUpdate() {
  const dispatch = useDispatch();
  const [{ data, loading, error }, update] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.THIRD_PARTY_UPDATE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  useEffect(() => {
    if (data) {
      setUserDetails(dispatch, data);
    }
  }, [data, dispatch]);

  return [{ data, loading, error }, update];
}

export function useUserLogout() {
  const dispatch = useDispatch();
  const [{ data, loading, error }] = useAxios({
    url: `${HOST}${APIBASE}${ACCOUNT.REVOKE_TOKEN}`,
    method: METHODS.POST,
    standardResponse: true,
    data: {},
  });

  useEffect(() => {
    if (data || error) {
      deleteAccessToken();
      deleteRefreshToken();
      deleteExpiredTime();
      userLogout(dispatch);
    }
  }, [dispatch, data, error]);

  return { data, loading, error };
}

export function useGetCurrentUser(isManual = false) {
  const dispatch = useDispatch();
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.CURRENT_USER}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    {
      manual: isManual,
    },
  );

  useEffect(() => {
    if (data) {
      switchTheme(data?.theme);
      const accessToken = getAccessToken();
      const refreshToken = getRefreshToken();
      setUserDetails(dispatch, { ...data, jwtToken: accessToken, refreshToken: refreshToken });
    }
  }, [data, dispatch]);

  return [{ data, loading, error }, refetch];
}

export const refreshTokenInterceptor = async (dispatch, store) => {
  const oldAccessToken = getAccessToken();
  const oldRefreshToken = getRefreshToken();
  if (oldAccessToken) {
    const refreshResult = await axios
      .post(`${HOST}${APIBASE}${ACCOUNT.REFRESH_TOKEN}`, { Token: oldRefreshToken })
      .catch((err) => {
        //Prevent multiple refreshtoken request, if first request success will update access token,
        //so other request access token will differernt with new access token.
        if (oldAccessToken === getAccessToken()) {
          deleteAccessToken();
        }
        if (oldRefreshToken === getRefreshToken()) {
          deleteRefreshToken();
        }
        return false;
      });
    setAccessToken(refreshResult.data.jwtToken);
    setRefreshToken(refreshResult.data.refreshToken);
    setUserDetails(dispatch, {
      ...store.user,
      accessToken: refreshResult.data.jwtToken,
      refreshToken: refreshResult.data.refreshToken,
    });
  }
  return true;
};

export function useUserRegister() {
  const [{ data, loading, error }, register] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.REGISTER}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, register];
}

export function useGetReferralName(referralCode) {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_NAME.replace(':code', referralCode)}`,
      method: METHODS.GET,
      standardResponse: true,
    },
    { manual: true },
  );

  const rData = data;
  const rLoading = loading;
  const rError = error;

  return [{ rData, rLoading, rError }, execute];
}

export function useGetReferralNameByDomain(domain) {
  const [{ data, loading, error }, executeDomain] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_NAME_DOMAIN.replace(':code', domain)}`,
      method: METHODS.GET,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, executeDomain];
}

export function useForgotPassword() {
  const [{ data, loading, error }, register] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.FORGOT_PASSWORD}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, register];
}

export function useResetPassword() {
  const [{ data, loading, error }, reset] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.RESET_PASSWORD}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, reset];
}

export function useUpdateAccount() {
  const dispatch = useDispatch();
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.UPDATE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  useEffect(() => {
    if (data) {
      setUserDetails(dispatch, data);
    }
  }, [data, dispatch]);
  return [{ data, loading, error }, execute];
}
export function useGetMemberSummary(startDate = null, endDate = null) {
  const [{ data, loading, error }, refetch] = useAxios({
    url:
      startDate !== null && endDate !== null
        ? `${HOST}${APIBASE}${ACCOUNT.SUMMARY.replace(':startDate', startDate).replace(
            ':endDate',
            endDate,
          )}`
        : `${HOST}${APIBASE}${ACCOUNT.SUMMARY_DEFAULT}`,
    method: METHODS.GET,
  });
  return [{ data, loading, error }, refetch];
}
export function useVerifyAccount() {
  const [{ data, loading, error }, register] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.VERIFY}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, register];
}

export function useRequestVerifyAccount() {
  const dispatch = useDispatch();

  const [{ data, loading, error }, update] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.REQUEST_VERIFY}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  useEffect(() => {
    if (error && error.indexOf('been verified') > -1) {
      setUserDetails(dispatch, { isVerified: true });
    }
  }, [error, dispatch]);

  return [{ data, loading, error }, update];
}

export function useSendVerifySms() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.SENDSMS}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useVerifySms() {
  const [{ data, loading, error }, verify] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.VERIFYSMS}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, verify];
}

export function useVerifyEmailSMS() {
  const [{ data, loading, error }, verify] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.VERIFY_EMAIL_SMS}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, verify];
}

export function useSendVerifyEmail() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.EMAIL_VERIFICATION_CODE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useVerifyRecaptcha() {
  const [{ data, loading, error }, verifyCaptcha] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.CAPTCHAV2_VERIFY}`,
      method: METHODS.POST,
    },
    { manual: true },
  );

  return [{ data, loading, error }, verifyCaptcha];
}

export function useVerifyCaptchaV1() {
  const [{ data, loading, error }, verifyCaptchaV1] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.CAPTCHAV1_VERIFY}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, verifyCaptchaV1];
}

export function useCaptchaV1() {
  const [{ data, loading, error }, getCaptchaV1] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.CAPTCHAV1}`,
      method: METHODS.GET,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, getCaptchaV1];
}

export function useCaptchaV2() {
  const [{ data, loading, error }, getCaptchaV2] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.CAPTCHAV2}`,
      method: METHODS.GET,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, getCaptchaV2];
}

export function useValidateResetCode() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.VALIDATE_RESET_CODE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useGetReferredMembers(startDate, endDate) {
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.REFERRED_MEMBER_LIST.replace(
        ':startDate',
        startDate,
      ).replace(':endDate', endDate)}`,
      method: METHODS.GET,
      standardResponse: true,
    },
    { manual: true },
  );
  return [{ data, loading, error }, refetch];
}

export function useGetThirdPartyScriptInfo(memberReferralCode) {
  const [{ data, loading, error }, getInfo] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.GET_METAPIXEL_INFO.replace(':code', memberReferralCode)}`,
      method: METHODS.GET,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, getInfo];
}

export function useLogDownloadAppIp(memberReferralCode) {
  const [{ data, loading, error }, logInfo] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.LOG_DOWNLOADAPPIP.replace(':code', memberReferralCode)}`,
      method: METHODS.GET,
    },
    { manual: true },
  );

  return [{ data, loading, error }, logInfo];
}

export function useGetCommissionHistory() {
  const [{ data, loading, error }] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_COMMISSION_HISTORY}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: false },
  );
  return [{ data, loading, error }];
}

export function useGetDetailedData() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_DETAILED_DATA}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );
  return [{ data, loading, error }, execute];
}
export function useGetCommissionRate() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_COMMISSION_RATE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: false },
  );
  return [{ data, loading, error }, execute];
}
export function useGetAffTeamDetails() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_TEAM_DETAILS}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );
  return [{ data, loading, error }, execute];
}

export function useGetAffLevelBettingDetails() {
  const [{ data, loading, error }, execute] = useAxios(
    {
      url: `${HOST}${APIBASE}${AFFILIATE.GET_LEVELBETTING_DETAILS}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, execute];
}

export function useResubscribeEmail() {
  const [{ data, loading, error }, subscribe] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.SUBSCRIBE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, subscribe];
}

export function useUnsubscribeEmail() {
  const [{ data, loading, error }, unsubscribe] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.UNSUBSCRIBE}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );
  return [{ data, loading, error }, unsubscribe];
}

export function useUpdateMetaEvt() {
  const [{ data, loading, error }, updateMetaEvt] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.UPDATE_META_EVT}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return [{ data, loading, error }, updateMetaEvt];
}

export function useVerifyPassword() {
  const [, verifyPassword] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.VERIFY_PASSWORD}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return verifyPassword;
}

export function useRemoveMemberPhoneOrEmail(isEmail) {
  const [, removeMemberPhoneOrMobile] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.REMOVE_MEMBER_PHONE_OR_EMAIL}?isEmail=${isEmail}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );

  return removeMemberPhoneOrMobile;
}

export function useGetBaseSetting(baseSettingKey, webCode) {
  const [{ data, loading, error }, getBaseSetting] = useAxios(
    {
      url: `${HOST}${APIBASE}${BASESETTING.GET_BASE_SETTING}`,
      method: METHODS.GET,
      params: {
        baseSettingKey: baseSettingKey,
        webCode: webCode,
      },
    },
    { manual: true },
  );
  return [{ data, loading, error }, getBaseSetting];
}

export function useRequestEmailOTP() {
  const dispatch = useDispatch();

  const [{ data, loading, error }, requestOTP] = useAxios(
    {
      url: `${HOST}${APIBASE}${ACCOUNT.REQUEST_VERIFY_EMAIL_OTP}`,
      method: METHODS.POST,
      standardResponse: true,
    },
    { manual: true },
  );
  return [{ data, loading, error }, requestOTP];
}
