import errorReducer from '../common/reducers/errorReducer';
import userReducer from '../common/reducers/userReducer';
import messageReducer from '../common/reducers/messageReducer';
import ipAddressReducer from '../common/reducers/ipAddressReducer';
import commonReducer from 'common/reducers/commonReducer';
import referralReducer from 'common/reducers/referralReducer';
import popupReducer from 'common/reducers/popupReducer';
import countdownReducer from 'common/reducers/countdownReducer';

/**
 * Function is super basic analog to Redux's combineReducers().
 * @param {Object} state Current application state.
 * @param {Action} action Dispatched action.
 * @returns New application state.
 */

export default function appReducer(state, action) {
  const { error, user, message, ipAddress, common, referral, popup, countdown } = state;
  const updatedState = {
    common: commonReducer(common, action),
    error: errorReducer(error, action),
    user: userReducer(user, action),
    ipAddress: ipAddressReducer(ipAddress, action),
    message: messageReducer(message, action),
    referral: referralReducer(referral, action),
    popup: popupReducer(popup, action),
    countdown: countdownReducer(countdown, action),
  };
  return updatedState;
}
