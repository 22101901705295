const en = {
  app: {
    title: 'dmwin.net',
    submit_btn: 'Submit',
    close_btn: 'Close',
    cancel_btn: 'Cancel',
    back_btn: 'Back',
    real_play_btn: 'Real Play',
    fun_play_btn: 'Fun Play',
    go_back_homepage_btn: 'Go back homepage',
    home_btn: 'Home',
    contactus_btn: 'Contact Us',
  },
  login: {
    deposit: 'Deposit',
    withdraw: 'Withdrawal',
    signin: 'Sign In',
    email: 'Email',
    phone: 'Phone',
    register: 'Register',
    has_account: 'New to DmWin?',
    create_account: 'Create an account',
    social_media: 'Log in directly with',
    username: 'Username / Email',
    password: 'Password',
    forgot_ps: 'Forgot Password?',
    confirm_ps: 'Confirm Password',
    successfully: 'Login Successfully',
    invalid_username_email: 'Please enter minimum 6 characters',
    invalid_username: 'Please enter 6-20 alphanumeric characters',
    invalid_password: 'Invalid password',
    password_not_match: 'The password and confirm password do not match',
    username_not_able_changed: 'Username will not be able to changed once submitted!',
    token_not_null: 'Access token cannot be null.',
    step_title1: 'One final step to set up your account',
    step_title2: 'Before you can start, please enter your username and password.',
    signin_facebook: 'SIGN IN WITH FACEBOOK',
    verify_acc: 'Verify Account',
    email_verified: 'Your email is verified!',
    invalid_verify_token: 'Invalid Verification Token!',
    contact_customer_service: 'Please contact customer service.',
  },
  forgotPassword: {
    forgotPassword: 'Forgot Password',
  },
  resetPassword: {
    title: 'Reset Password',
    token_not_found: 'Reset password token not found',
  },
  topbar: {
    member_center: 'Member Center',
    transactions: 'Transactions',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    vip: 'VIP & Bonus',
    affiliate: 'Affiliate',
    logout: 'Logout',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    vipProgress: 'VIP Progress',
    welcomeBonusTile: 'Slots 300% welcome pack up to 90000INR.',
    affiliateInviteTile: 'Become a partner of DmWin，Earn the highest commission in India.',
    inviteBtn: 'INVITE',
    joinNowBtn: 'JOIN NOW',
    voucher: 'Redemption Code',
    vault: 'Vault',
  },
  home: {
    bet_directly: 'Bet Directly',
    more_btn: 'more',
    title_popular: 'Popular',
    title_slots: 'Slots',
    title_casino: 'Casino',
    table_games: 'Games',
    table_payout: 'Payout amount',
    noShowPopUpText: "Don't show again today",
  },
  sidebar: {
    bonus: 'Promotion',
    vip: 'VIP',
    live_support: 'Contact Us',
    Sports: 'Sports',
    Slots: 'Slots',
    'Live Casino': 'Live Casino',
    Rummy: 'Rummy',
    'Teen Patti': 'Teen Patti',
    'India Poker': 'India Poker',
    All: 'All',
    Recent: 'Recent',
    Favourite: 'Favourite',
    'New Releases': 'New Releases',
    Popular: 'Popular',
    'Feature Buy In': 'Feature Buy In',
    Blackjack: 'BlackJack',
    Baccarat: 'Baccarat',
    Roulette: 'Roulette',
    download_app: 'DOWNLOAD APP',
    Providers: 'Providers',
    LiveEvents: 'Live Events',
    Cricket: 'Cricket',
    Football: 'Football',
    Hockey: 'Hockey',
    Badminton: 'Badminton',
    Tennis: 'Tennis',
    Wrestling: 'Wrestling',
    Basketball: 'Basketball',
    Baseball: 'Baseball',
    Boxing: 'Boxing',
    Dota2: 'Dota2',
    CSGO: 'CSGO',
    Promotion: 'Promotion',
    VIP: 'VIP',
    Affiliate: 'Affiliate',
    Original: 'Original',
    Poker: 'Poker',
  },
  footer: {
    home: 'Home',
    support: 'Support',
    deposits: 'Deposits',
    menu: 'Menu',
    slots: 'Slots',
    casino: 'Casino',
    sports: 'Sports',
    bonus: 'Promotion',
    aviator: 'Aviator',
    title: 'DmWin',
    text1:
      "DmWin is the perfect online casino for Indian players looking to play with real money. We offer a wide range of real money online casino games that can be played with high or low bet amounts, making it easy to find the perfect game for your budget.\n\nWhat's more, we offer fast withdrawals and easy deposits using trusted Indian payment methods like UPI bank transfers and PhonePe.\n\nWhen you create a DmWin account you get a clear overview of your funds, while our regular promotions help boost your bankroll.\n\nSo if you're looking for a safe and secure real money online casino to play at, DmWin is the perfect choice.",
    join_community: 'Join our Community',
    app: 'App',
    sportsLobby: 'Sports Lobby',
    slotsLobby: 'Slots Lobby',
    casinoLobby: 'Casino Lobby',
    originalLobby: 'Original Lobby',
    pokerLobby: 'Poker Lobby',
    appDownload: 'App Download',
    entertainment: 'Entertainment',
  },
  register: {
    signUp: 'Sign Up',
    name: 'Name',
    username: 'Username',
    password: 'Password',
    confirm_ps: 'Confirm Password',
    email: 'Email',
    mobile_number: 'Mobile Number',
    invite_code: 'ReferralCode',
    captcha: 'Captcha',
    term1: 'I agree to the <link>User Agreement</link> ',
    term2: ', <link>Privacy Policy</link> ',
    term3: ', <link>Terms of Service</link> & confirm that I am at least 18 years old',
    marketingTerm: 'I agree to receive marketing promotions from DmWin',
    btn: 'Sign Up',
    has_account: 'Already have an account?',
    invalid_mobile: 'Invalid mobile number',
    invalid_password: 'Invalid password',
    send_otp: 'Send OTP',
    phone_number: 'Phone Number',
    reg_username_charc_valid: 'Username must contain letters and numbers',
    reg_username_valid: 'Username must be at least 6~15 characters',
    reg_username_special_charc: 'Username cannot contain special characters',
    ln_valid: 'Please enter 6-20 alphanumeric characters',
    email_valid: 'Please enter a valid email',
    ps_valid: 'Please enter minimum 6 characters',
    confirm_ps_invalid: 'Re-enter password should be the same with password',
    verify_otp: 'Please verify your phone number by OTP',
    accept_terms: 'Please accept the terms',
    otp_title1: 'OTP',
    otp_title2: 'Verification Code',
    otp_placeholder: 'Enter the 4-digit One-time Password (OTP) sent to your mobile number',
    otp_label1: 'Not your mobile number?',
    otp_label2: "Didn't you receive the OTP?",
    otp_label3: 'Please wait',
    otp_label4: 's to resend.',
    resend_otp: 'Resend OTP',
    verify_btn: 'VERIFY',
    mail_confirmation: 'Mail Confirmation',
    confirmation_text1:
      "We've sent an email to {email} to verify your email address and activate your account.",
    confirmation_text2: 'The link in the email will expire in 5 minutes.',
    missing_email1:
      'Oops! It seems like you have reached this page without initiating the signup process.',
    missing_email2:
      'If you are trying to sign up for an account, please use the provided signup button to receive a verification email.',
    missing_email3:
      'If you need assistance or have any questions, feel free to contact our support team.',
    create_account: 'Create Account',
    user_agreement_title1: 'User Agreement',
    user_agreement_title2: 'Terms of Service',
    user_agreement_content:
      'This end user agreement (the "Agreement") should be read by you (the "User" or "you") in its entirety prior to your use of DmWin’s service or products. Please note that the Agreement constitutes a legally binding agreement between you and DmWin (referred to herein as "DmWin", "us" or "we") which owns and operates the Internet site found and games described at DmWin (the "Service"). By clicking the "I Agree" button if and where provided and/or using the Service, you consent to the terms and conditions set forth in this Agreement.',
    user_agreement_content1: '1. Grant of License',
    user_agreement_content1_1:
      ' 1.1. Subject to the terms and conditions contained herein, DmWin grants the User a non-exclusive, personal, non-transferable right to use the Service on your personal computer or other device that accesses the Internet in order to access the games ava',
    user_agreement_content1_2:
      ' 1.2. The Service is not for use by (i) individuals under 18 years of age, (ii) individuals under the legal age of majority in their jurisdiction and (iii) individuals accessing the Service from jurisdictions from which it is illegal to do so. DmWin is not able to verify the legality of the Service in each jurisdiction and it is the User’s responsibility to ensure that their use of the Service is lawful.',
    user_agreement_content1_3:
      '1.3. DmWin and its licensors are the sole holders of all rights in and to the Service and code, structure and organization, including copyright, trade secrets, intellectual property and other rights. You may not, within the limits prescribed by applicable laws: (a) copy, distribute, publish, reverse engineer, decompile, disassemble, modify, or translate the website; or (b) use the Service in a manner prohibited by applicable laws or regulations (each of the above is an "Unauthorized Use"). DmWin reserves any and all rights implied or otherwise, which are not expressly granted to the User hereunder and retain all rights, title and interest in and to the Service. You agree that you will be solely liable for any damage, costs or expenses arising out of or in connection with the commission by you of any Unauthorized Use. You shall notify DmWin immediately upon becoming aware of the commission by any person of any Unauthorized Use and shall provide DmWin with reasonable assistance with any investigations it conducts in light of the information provided by you in this respect.',
    user_agreement_content1_4:
      '1.4. The term "DmWin", its domain names and any other trade marks, or service marks used by DmWin as part of the Service (the "Trade Marks"), are solely owned by DmWin In addition, all content on the website, including, but not limited to, the images, pictures, graphics, photographs, animations, videos, music, audio and text (the "Site Content") belongs to DmWin and is protected by copyright and/or other intellectual property or other rights. You hereby acknowledge that by using the Service, you obtain no rights in the Site Content and/or the Trade Marks, or any part thereof. Under no circumstances may you use the Site Content and/or the Trade Marks without DmWin’s prior written consent. Additionally, you agree not to do anything that will harm or potentially harm the rights, including the intellectual property rights of DmWin',
    user_agreement_content2: '2. No Warranties',
    user_agreement_content2_1:
      '2.1. DmWin disclaims any and all warranties, expressed or implied, in connection with the service which is provided to you "as is" and we provide you with no warranty or representation whatsoever regarding its quality, fitness for purpose, completeness or accuracy.',
    user_agreement_content2_2:
      '2.2. Regardless of DmWin’s efforts, DmWin makes no warranty that the service will be uninterrupted, timely or error-free, or that defects will be corrected.',
    user_agreement_content3:
      '3. Authority/Terms of Service You agree to the game rules described on the DmWin website. DmWin retains authority over the issuing, maintenance, and closing of the Service. The decision of DmWin’s management, concerning any use of the Service, or dispute resolution, is final and shall not be open to review or appeal.',
    user_agreement_content4: '4. Your Obligations as a Player',
    user_agreement_content4_1: '4.1. You hereby declare and warrant that:',
    user_agreement_content4_1_1:
      '4.1.1. You are over 18 years of age or such a higher minimum legal age of majority as stipulated if the jurisdiction of Your residence (e.g. Estonia – 21 years) and, under the laws applicable to You, legally allowed to participate in the Games offered on the Website.',
    user_agreement_content4_1_2:
      '4.1.2. You participate in the Games strictly in your personal non-professional capacity for recreational and entertainment reasons only.',
    user_agreement_content4_1_3:
      '4.1.3. You participate in the Games on your own behalf and not on behalf of any other person.',
    user_agreement_content4_1_4:
      '4.1.4. All information that You provide to DmWin during the term of validity of this agreement is true, complete, and correct, and that You shall immediately notify DmWin of any change of such information.',
    user_agreement_content4_1_5:
      '4.1.5. You are solely responsible for reporting and accounting for any taxes applicable to You under relevant laws for any winnings that You receive from DmWin.',
    user_agreement_content4_1_6:
      '4.1.6. You understand that by participating in the Games you take the risk of losing Virtual Funds deposited into Your Member Account.',
    user_agreement_content4_1_7:
      '4.1.7. You shall not be involved in any fraudulent, collusive, fixing or other unlawful activity in relation to Your or third parties’ participation in any of the Games and shall not use any software- assisted methods or techniques or hardware devices for Your participation in any of the Games. DmWin hereby reserves the right to invalidate any wager in the event of such behaviour.',
    user_agreement_content4_1_8:
      '4.1.8. You understand that Virtual Funds as Bitcoin are not considered a legal currency or tender and as such on the Website they are treated as virtual funds with no intrinsic value.',
    user_agreement_content4_1_9:
      '4.1.9. You understand that Bitcoin value can change dramatically depending on the market value.',
    user_agreement_content4_1_10:
      '4.1.10. You are not allowed to use any payment methods that belong to a Third party or person.',
    user_agreement_content4_2:
      '4.2. You are not allowed to transfer, sell and/or acquire, user accounts.',
    user_agreement_content4_3:
      '4.3. Games played on Our site should be played in the same manner as games played in any other setting. This means that players should be courteous to each other and avoid rude or obscene comments.',
    user_agreement_content4_4:
      '4.4. Some circumstances may arise where a wager is confirmed, or a payment is performed by us in error. In all these cases DmWin reserves the right to cancel all the wagers accepted containing such an error.',
    user_agreement_content4_5:
      '4.5. Should the user become aware of possible errors or incompleteness in the software, he/she agrees to refrain from taking advantage of them. Moreover, the user agrees to report any error or incompleteness immediately to DmWin Should the user fail to fulfil the obligations stated in this clause, DmWin has a right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective error/incompleteness and the failed notification by the user.',
    user_agreement_content4_6:
      '4.6. In the event a game is started but miscarried because of a failure of the system, DmWin shall refund the amount wagered in the game to the User by crediting it to the User’s Account or, if the account no longer exists, by paying it to the User in an approved manner; and if the User has an accrued credit at the time the game miscarried, credit to the User’s Account the monetary value of the credit or, if the account no longer exists, pay it to the User in an approved manner.',
    user_agreement_content4_7:
      '4.7. DmWin reserves the right to reject or limit wagers. The user is not permitted to wager an amount exceeding his/her personal account. Wins are credited to the personal account of the user.',
    user_agreement_content4_8:
      '4.8. DmWin reserves the right to retain payments, if suspicion or evidence exists of manipulation of the casino system. Criminal charges will be brought against any user or any other person(s), who has/have manipulated the casino system or attempted to do so. DmWin reserves the right to terminate and/or, change any games or events being offered on the Website.',
    user_agreement_content4_9:
      '4.9. We reserve the right to require some verification in case of suspicious or fraudulent transactions.',
    user_agreement_content4_10:
      '4.10. DmWin reserves the right to declare a wager void partially or in full if DmWin, at its own discretion, would deem it obvious that any of the following circumstances have occurred:',
    user_agreement_content4_10_1:
      '4.10.1. You, or people associated with you may directly or indirectly influence the outcome of an event, to obtain an unlawful advantage,',
    user_agreement_content4_10_2:
      '4.10.2. You and or people associated with you are directly or indirectly avoiding the rules of DmWin',
    user_agreement_content4_10_3:
      '4.10.3. The result of an event has been directly or indirectly affected by criminal activity.',
    user_agreement_content4_10_4:
      '4.10.4. Wagers have been placed that would not have been accepted otherwise, but that were accepted during periods when the website have been affected by technical problems.',
    user_agreement_content4_10_5:
      '4.10.5. Due to an error, such as a, misprint, technical error, force majeure or otherwise, wagers have been offered, placed and or accepted due to this error.',
    user_agreement_content4_10_6:
      "4.10.6. If a player's deposit fee is too low and is flagged by blockchain or similar site as “not enough fee to relay” DmWin reserve the right to confiscate the winnings if DmWin at their own discretion deem the transaction and behavior of the player to be fraudulent in nature.",
    user_agreement_content4_11:
      '4.11. You will inform DmWin immediately if you enter into a self-exclusion agreement with any gambling provider.',
    user_agreement_content5: '5. Prohibited Uses',
    user_agreement_content5_1:
      '5.1. PERSONAL USE. The Service is intended solely for the User’s personal use. The User is only allowed to wager for his/her personal entertainment and may not create multiple accounts, including for the purpose of collusion and/or abuse of service.',
    user_agreement_content5_2:
      '5.2. JURISDICTIONS. Persons located in or residents of Aruba, Bonaire, Curacao, Costa Rica, France, Netherlands, Saba, Statia, St Martin, USA (the ”Prohibited Jurisdictions”) are not permitted make use of the Service. For the avoidance of doubt, the foregoing restrictions on engaging in real-money play from Prohibited Jurisdictions applies equally to residents and citizens of other nations while located in a Prohibited Jurisdiction. Any attempt to circumvent the restrictions on play by any persons located in a Prohibited Jurisdiction or Restricted Jurisdiction, is a breach of this Agreement. An attempt at circumvention includes, but is not limited to, manipulating the information used by DmWin to identify your location and providing DmWin with false or misleading information regarding your location or place of residence.',
    user_agreement_content6: '6. Know your Customer (“KYC”)',
    user_agreement_content6_1:
      'DmWin reserves the right, at any time, to ask for any KYC documentation it deems necessary to determine the identity and location of a User. DmWin reserves the right to restrict service and payment until identity is sufficiently determined.',
    user_agreement_content7: '7. Breach',
    user_agreement_content7_1:
      '7.1. Without prejudice to any other rights, if a User breaches in whole or in part any provision contained herein, DmWin reserves the right to take such action as it sees fit, including terminating this Agreement or any other agreement in place with the User and/or taking legal action against such User.',
    user_agreement_content7_2:
      '7.2. You agree to fully indemnify, defend and hold harmless DmWin and its shareholders, directors, agents and employees from and against all claims, demands, liabilities, damages, losses, costs and expenses, including legal fees and any other charges whatsoever, howsoever caused, that may arise as a result of: (i) your breach of this Agreement, in whole or in part; (ii) violation by you of any law or any third party rights; and (iii) use by you of the Service.',
    user_agreement_content8: '8. Limitations and Liability',
    user_agreement_content8_1:
      '8.1. Under no circumstances, including negligence, shall DmWin be liable for any special, incidental, direct, indirect or consequential damages whatsoever (including, without limitation, damages for loss of business profits, business interruption, loss of business information, or any other pecuniary loss) arising out of the use (or misuse) of the Service even if DmWin had prior knowledge of the possibility of such damages.',
    user_agreement_content8_2:
      '8.2. Nothing in this Agreement shall exclude or limit DmWin’s liability for death or personal injury resulting from its negligence.',
    user_agreement_content9: '9. Disputes',
    user_agreement_content9_1:
      'If a User wishes to make a complaint, please contact DmWin’s customer service team at support@DmWin. Should any dispute not be resolved to your satisfaction you may pursue remedies in the governing law jurisdiction set forth below.',
    user_agreement_content10: '10. Amendment',
    user_agreement_content10_1:
      'DmWin reserves the right to update or modify this Agreement or any part thereof at any time or otherwise change the Service without notice and you will be bound by such amended Agreement upon posting. Therefore, we encourage you to check the terms and conditions contained in the version of the Agreement in force at such time. Your continued use of the Service shall be deemed to attest to your agreement to any amendments to the Agreement.',
    user_agreement_content11: '11. Governing Law',
    user_agreement_content11_1:
      'The Agreement and any matters relating thereto shall be governed by, and construed in accordance with, the laws of Costa Rica. You irrevocably agree that, subject as provided below, the courts of Costa Rica shall have exclusive jurisdiction in relation to any claim, dispute or difference concerning the Agreement and any matter arising therefrom and irrevocably waive any right that it may have to object to an action being brought in those courts, or to claim that the action has been brought in an inconvenient forum, or that those courts do not have jurisdiction. Nothing in this clause shall limit the right of DmWin to take proceedings against you in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction.',
    user_agreement_content11_2:
      'If a provision of this Agreement is or becomes illegal, invalid or unenforceable in any jurisdiction, that shall not affect the validity or enforceability in that jurisdiction of any other provision hereof or the validity or enforceability in other jurisdiction of that or any other provision hereof.',
    user_agreement_content13: '13. Assignment',
    user_agreement_content13_1:
      'DmWin reserves the right to assign this agreement, in whole or in part, at any time without notice. The User may not assign any of his/her rights or obligations under this Agreement.',
    user_agreement_content14: '14. ADVANTAGE PLAY',
    user_agreement_content14_1:
      'Should the Casino become aware of any user who has accepted the bonus or a promotion with sole purpose of creating a positive expected value on bonus return by using known practices aimed at securing a cash out of said bonus or at any way try to take advantage of bonuses received by DmWin, then DmWin will enforce immediate confiscation of winnings and closure of the account with the right to withhold any further withdrawals. An example of advantage play would be delaying any game round in any game, including free spins features and bonus features, to a later time when you have no more wagering requirement and/or performing new deposit(s) while having free spins features or bonus features still available in a game. In the interests of fair gaming, equal, zero or low margin bets or hedge betting, shall all be considered irregular gaming for bonus play- through requirement purposes. Should the Casino deem that irregular game play has occurred, the Casino reserves the right to withhold any withdrawals and/or confiscate all winnings.',
    user_agreement_def_1: 'Definitions;',
    user_agreement_def_2: "DmWin is referred to as 'we' or 'us'.",
    user_agreement_def_3: "The Player is referred to as 'you' or 'the Player'",
    user_agreement_def_4:
      "'The Website' means DmWin through desktop, mobile or other platforms utilised by the Player",
    user_agreement_def_5: 'https://DmWin/help/terms-service',
    user_agreement_gen_1: '1. General',
    user_agreement_gen_1_1:
      '1.1. This User Agreement applies to the usage of games accessible through DmWin.',
    user_agreement_gen_1_2:
      '1.2. This User Agreement comes into force as soon as you complete the registration process, which includes checking the box accepting this User Agreement and successfully creating an account. By using any part of the Website following account creation, you agree to this User Agreement.',
    user_agreement_gen_1_3:
      '1.3. You must read this User Agreement carefully in their entirety before creating an account. If you do not agree with any provision of this User Agreement, you must not create an account or continue to use the Website.',
    user_agreement_gen_1_4:
      '1.4. We are entitled to make amendments to this User Agreement at any time and without advanced notice. If we make such amendments, we may take appropriate steps to bring such changes to your attention (such as by e-mail or placing a notice on a prominent position on the Website, together with the amended User Agreement) but it shall be your sole responsibility to check for any amendments, updates and/or modifications. Your continued use of DmWin services and Website after any such amendment to the User Agreement will be deemed as your acceptance and agreement to be bound by such amendments, updates and/or modifications.',
    user_agreement_gen_1_5:
      '1.5. this User Agreement may be published in several languages for informational purposes and ease of access by players. The English version is the only legal basis of the relationship between you and us and in the case of any discrepancy with respect to a translation of any kind, the English version of this User Agreement shall prevail.',
    user_agreement_gen_2: '2. Binding Declarations',
    user_agreement_gen_2_1:
      '2.1. By agreeing to be bound by this User Agreement, you also agree to be bound by the DmWin Rules and Privacy Policy that are hereby incorporated by reference into this User Agreement. In the event of any inconsistency, this User Agreement will prevail. You hereby represent and warrant that:',
    user_agreement_gen_2_1_1:
      '2.1.1. You are over (a) 18 or (b) such other legal age or age of majority as determined by any laws which are applicable to you, whichever age is greater;',
    user_agreement_gen_2_1_2:
      '2.1.2. You have full capacity to enter into a legally binding agreement with us and you are not restricted by any form of limited legal capacity;',
    user_agreement_gen_2_1_3:
      '2.1.3. All information that you provide to us during the term of validity of this agreement is true, complete, correct, and that you shall immediately notify us of any change of such information;',
    user_agreement_gen_2_1_4:
      '2.1.4. You are solely responsible for reporting and accounting for any taxes applicable to you under relevant laws for any winnings that you receive from us;',
    user_agreement_gen_2_1_5:
      '2.1.5. You understand that by using our services you take the risk of losing money deposited into your Member Account and accept that you are fully and solely responsible for any such loss;',
    user_agreement_gen_2_1_6:
      '2.1.6. You are permitted in the jurisdiction in which you are located to use online casino services;',
    user_agreement_gen_2_1_7:
      '2.1.7. In relation to deposits and withdraws of funds into and from your Member Account, you shall only use Cryptocurrency that are valid and lawfully belong to you;',
    user_agreement_gen_2_1_8:
      '2.1.8. You understand that the value of Cryptocurrency can change dramatically depending on the market value;',
    user_agreement_gen_2_1_9:
      '2.1.9. The computer software, the computer graphics, the Websites and the user interface that we make available to you are owned by DmWin or its associates and is protected by copyright laws. You may only use the software for your own personal, recreational uses in accordance with all rules, User Agreement we have established and in accordance with all applicable laws, rules and regulations;',
    user_agreement_gen_2_1_10:
      '2.1.10. You understand that Cryptocurrency is not considered a legal currency or tender and as such on the Website they are treated as virtual funds with no intrinsic value.',
    user_agreement_gen_2_1_11:
      '2.1.11. You affirm that you are not an officer, director, employee, consultant or agent of DmWin or working for any company related to DmWin, or a relative or spouse of any of the foregoing;',
    user_agreement_gen_2_1_12:
      '2.1.12. You are not diagnosed or classified as a compulsive or problem gambler. We are not accountable if such problem gambling arises whilst using our services but will endeavor to inform of relevant assistance available. We reserve the right to implement cool off periods if we believe such actions will be of benefit.',
    user_agreement_gen_2_1_13:
      '2.1.13. You accept and acknowledge that we reserve the right to detect and prevent the use of prohibited techniques, including but not limited to fraudulent transaction detection, automated registration and signup, gameplay and screen capture techniques. These steps may include, but are not limited to, examination of Players device properties, detection of geo-location and IP masking, transactions and blockchain analysis;',
    user_agreement_gen_2_1_14:
      '2.1.14. You accept our right to terminate and/or change any games or events being offered on the Website, and to refuse and/or limit bets.',
    user_agreement_gen_2_1_15:
      '2.1.15. You accept that we have the right to ban/block multiple accounts and freely control the assets in such accounts.',
    user_agreement_gen_2_1_16:
      '2.1.16. You are aware of possible errors or incompleteness in the software, you agree to refrain from taking advantage of them. Moreover, you agree to report any error or incompleteness immediately to DmWin. Should you fail to fulfil the obligations stated in this clause, DmWin has a right to full compensation for all costs related to the error or incompleteness, including any costs incurred in association with the respective error/incompleteness and the failed notification by the user.',
    user_agreement_gen_2_1_17:
      '2.1.17. You are aware of that DmWin has the right to carry out “KYC” (Know Your Customer) verification procedures. The access to your user account may be blocked or closed if we determine that you have supplied false or misleading information.',
    user_agreement_gen_2_2:
      '2.2. We reserve the right to declare a wager void partially or in full if DmWin, at its own discretion, would deem it obvious that any of the following circumstances have occurred:',
    user_agreement_gen_2_2_1:
      '2.2.1. You, or people associated with you, may directly or indirectly influence the outcome of an event, to obtain an unlawful advantage.',
    user_agreement_gen_2_2_2:
      '2.2.2. You and or people associated with you are directly or indirectly avoiding the rules of DmWin.',
    user_agreement_gen_2_2_3:
      '2.2.3. The result of an event has been directly or indirectly affected by criminal activity.',
    user_agreement_gen_2_2_4:
      '2.2.4. Wagers have been placed that would not have been accepted otherwise, but that were accepted during periods when the website has been affected by technical problems.',
    user_agreement_gen_2_2_5:
      '2.2.5. Due to an error, such as a mistake, vulnerabilities, technical error, force majeure or otherwise, wagers have been offered, placed and or accepted due to this error.',
    user_agreement_gen_2_2_6:
      "2.2.6. If a player's deposit fee is too low and is flagged by blockchain or similar site as “not enough fee to relay” DmWin reserves the right to confiscate the winnings if DmWin at their own discretion deems the transaction and behavior of the player to be fraudulent in nature.",
    user_agreement_gen_3: '3. Restricted Territories',
    user_agreement_gen_3_1:
      '3.1. Blacklisted Territories: China, Netherlands, Dutch Caribbean Islands, Hungary, Australia, Ontario(Canada), Curacao, France, United States and/or any other restricted by law country or state. Note that it is strictly forbidden to play on DmWin games in blacklisted countries mentioned above.your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.',
    user_agreement_gen_4: '4. General Betting Rules',
    user_agreement_gen_4_1: '4.1. A bet can only be placed by a registered account holder.',
    user_agreement_gen_4_2: '4.2. A bet can only be placed over the internet.',
    user_agreement_gen_4_3:
      '4.3. You can only place a bet if you have a sufficient balance in your account with DmWin.',
    user_agreement_gen_4_4:
      '4.4. The bet, once concluded, will be governed by the version of the User Agreement valid and available on the Website at the time of the bet being accepted.',
    user_agreement_gen_4_5:
      '4.5. Any payout of a winning bet is credited to your account, consisting of the stake multiplied by the odds at which the bet was placed.',
    user_agreement_gen_4_6:
      '4.6. DmWin reserves the right to adjust a bet payout credited to a DmWin account if it is determined by DmWin in its sole discretion that such a payout has been credited due to an error.',
    user_agreement_gen_4_7:
      '4.7. A bet, which has been placed and accepted, cannot be amended, withdrawn, or cancelled by you.',
    user_agreement_gen_4_8:
      '4.8. The list of all the bets, their status and details are available to you on the Website.',
    user_agreement_gen_4_9:
      '4.9. When you place a bet you acknowledge that you have read and understood in full all of this User Agreement regarding the bet as stated on the Website.',
    user_agreement_gen_4_10:
      '4.10. DmWin manages your account, and calculates the available funds, the pending funds, the betting funds as well as the amount of winnings. Unless proven otherwise, these amounts are considered as final and are deemed to be accurate.',
    user_agreement_gen_4_11: '4.11. You are fully responsible for the bets placed.',
    user_agreement_gen_4_12:
      '4.12. Winnings will be paid into your account after the final result is confirmed.',
    user_agreement_gen_5: '5. Bonuses and Promotions',
    user_agreement_gen_5_1:
      '5.1. DmWin reserves the right to cancel any promotion, bonus or bonus program (including, but not limited to top-up rewards, invite friends to reward bonuses and loyalty programs) with immediate effect if we believe the bonus has been set up incorrectly or is being abused, and if the said bonus has been paid out, we reserve the right to decline any Withdraw request and to deduct such amount from your account. Whether or not a bonus is deemed to be set up incorrectly or abused shall be determined solely by DmWin.',
    user_agreement_gen_5_2:
      '5.2. If you use a Deposit Bonus, no Withdraw of your original deposit will be accepted before you have reached the requirements stipulated under the User Agreement of the Deposit Bonus.',
    user_agreement_gen_5_3:
      "5.3. Where any term of the offer or promotion is breached or there is any evidence of a series of bets placed by a customer or group of customers, which due to a deposit bonus, enhanced payments, free bets, risk free bets or any other promotional offer results in guaranteed customer profits irrespective of the outcome, whether individually or as part of a group, DmWin reserves the right to reclaim the bonus element of such offers and in their absolute discretion either settle bets at the correct odds, void the free bet bonus and risk free bets or void any bet funded by the deposit bonus. In addition, DmWin reserves the right to levy an administration charge on the customer up to the value of the deposit bonus, free bet bonus, risk free bet or additional payment to cover administrative costs. We further reserve the right to ask any customer to provide sufficient documentation for us to be satisfied in our absolute discretion as to the customer's identity prior to us crediting any bonus, free bet, risk free bet or offer to their account.",
    user_agreement_gen_5_4:
      '5.4. All DmWin offers are intended for recreational players and DmWin may in its sole discretion limit the eligibility of customers to participate in all or part of any promotion.',
    user_agreement_gen_5_5:
      '5.5. DmWin reserves the right to amend, cancel, reclaim or refuse any promotion at its own discretion.',
    user_agreement_gen_5_6:
      '5.6. Bonuses can only be received once per person/account, family, household, address, e-mail address, IP addresses and environments where computers are shared (university, fraternity, school, public library, workplace, etc.). The Operator reserves the right to close your account and confiscate any existing funds if evidence of abuse/fraud is found.',
    user_agreement_gen_5_7:
      '5.7. You acknowledge and understand that separate User Agreement exist with respect to promotions, bonuses and special offers, and are in addition to this User Agreement. This User Agreement is set forth in the respective content page on this website, or have been made available to you personally, as the case may be. In the event of a conflict between the provisions of such promotions, bonuses and special offers, and the provisions of this User Agreement, the provisions of such promotions, bonuses and special offers will prevail.',
    user_agreement_gen_5_8:
      '5.8. We may insist that you bet a certain amount of your own deposit before you can bet with any free/bonus funds we credit to your account.',
    user_agreement_gen_5_9:
      '5.9. You accept that certain promotions may be subject to Withdraw restrictions and/or requirements which need to be met before funds credited under the promotion can be withdrawn. Such terms shall be duly published and made available as part of the promotion. If you opt to make a Withdraw before the applicable wagering requirements are fulfilled, we will deduct the whole bonus amount as well as any winnings connected with the use of the bonus amounts before approving any Withdraw.',
    user_agreement_gen_6: '6. Live Chat',
    user_agreement_gen_6_1:
      '6.1. As part of your use of the Website we may provide you with a live chat facility, which is moderated by us and subject to controls. We reserve the right to review the chat and to keep a record of all statements made on the facility. Your use of the chat facility should be for recreational and socialising purposes.',
    user_agreement_gen_6_2:
      '6.2. We have the right to remove the chat room functionality or immediately terminate your Member Account and refund your account balance if you:',
    user_agreement_gen_6_2_a:
      '(a) make any statements that are sexually explicit or grossly offensive, including expressions of bigotry, racism, hatred or profanity;',
    user_agreement_gen_6_2_b:
      '(b) make statements that are abusive, defamatory or harassing or insulting;',
    user_agreement_gen_6_2_c:
      '(c) use the chat facility to advertise, promote or otherwise relate to any other online entities;',
    user_agreement_gen_6_2_d:
      '(d) make statements about DmWin, or any other Internet site(s) connected to the Website that are untrue and/or malicious and/or damaging to DmWin;',
    user_agreement_gen_6_2_e:
      '(e) user the chat facility to collude, engage in unlawful conduct or encourage conduct we deem seriously inappropriate. Any suspicious chats will be reported to the competent authority.',
    user_agreement_gen_6_3:
      '6.3. Live Chat is used as a form of communication between us and you and should not be copied or shared with any forums or third parties.',
    user_agreement_gen_7: '7. Limitation of Liability',
    user_agreement_gen_7_1:
      '7.1. You enter the Website and participate in the Games at your own risk. The Websites and the Games are provided without any warranty whatsoever, whether expressed or implied.',
    user_agreement_gen_7_2:
      '7.2. Without prejudice to the generality of the preceding provision, we, our directors, employees, partners, service providers.',
    user_agreement_gen_7_3:
      '7.3. Do not warrant that the software, Games and the Websites are fit for their purpose.',
    user_agreement_gen_7_4:
      '7.4. Do not warrant that the software, Games and the Websites are free from errors.',
    user_agreement_gen_7_5:
      '7.5. Do not warrant that the software, Games and the Websites will be accessible without interruptions.',
    user_agreement_gen_7_6:
      '7.6. Shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Websites or your participation in the Games.',
    user_agreement_gen_7_7:
      '7.7. You understand and acknowledge that, if there is a malfunction in a Game or its interoperability, any bets made during such a malfunction shall be void. Funds obtained from a malfunctioning Game shall be considered void, as well as any subsequent game rounds with said funds, regardless of what Games are played using such funds.',
    user_agreement_gen_7_8:
      '7.8. You hereby agree to fully indemnify and hold harmless us, our directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.',
    user_agreement_gen_7_9:
      '7.9. To the extent permitted by law, our maximum liability arising out of or in connection with your use of the Websites, regardless of the cause of actions (whether in contract, tort, breach of warranty or otherwise), will not exceed €100.',
    user_agreement_gen_8: '8. Breaches, Penalties and Termination',
    user_agreement_gen_8_1:
      '8.1. If you breach any provision of this User Agreement or we have a reasonable ground to suspect that you have breached them, we reserve the right to not open, to suspend, or to close your Member Account, or withhold payment of your winnings and apply such funds to any damages due by you.',
    user_agreement_gen_8_2:
      '8.2. You acknowledge that DmWin shall be the final decision-maker of whether you have violated DmWin’s rules, terms or conditions in a manner that results in your suspension or permanent barring from participation in our site.',
    user_agreement_gen_9: '9. Self-exclusion',
    user_agreement_gen_9_1:
      '9.1. By requesting a period of self-exclusion, you agree to follow the below terms and conditions, which will be in effect from the time that CS implements the chosen period of self-exclusion.',
    user_agreement_gen_9_2:
      '9.2. You may self-exclude for periods of 1, 3, 6, 12 month/s or permanent. Self-exclusion requests are to be made via Live Support.',
    user_agreement_gen_9_3:
      '9.3. Once you have self-excluded you will not be able to access your account or withdraw during this period.',
    user_agreement_gen_9_4:
      '9.4. If you have excluded your account whilst you have pending bets on your account, bets placed will remain valid and settle according to official results.',
    user_agreement_gen_9_5:
      '9.5. Once the period of self-exclusion has lapsed you may withdraw winnings from qualifying bets. DmWin does not cancel or void any bets placed before a self-exclusion has been affected.',
    user_agreement_gen_9_6:
      '9.6. Once you have self-excluded you will not be able to change or alter the period for a shorter length of time or have your self-exclusion cancelled until the period that you selected for self-exclusion has passed.',
    user_agreement_gen_9_7:
      '9.7. Please contact our customer services team if you wish to extend your self-exclusion period.',
    user_agreement_gen_9_8:
      '9.8. Once your self-exclusion period has elapsed, reinstatement of the account can be done by emailing the request to support@DmWin.',
    user_agreement_gen_9_9: '9.9. By self-excluding, you agree that:',
    user_agreement_gen_9_9_a: 'a) You will not create another account during this period.',
    user_agreement_gen_9_9_b:
      'b) You will not deposit or attempt to deposit funds into a DmWin account.',
    user_agreement_gen_9_9_c: 'c) You will not wager on this website during this period.',
    user_agreement_gen_9_9_d:
      'd) This is a voluntary act initiated by yourself, and BlockDance B.V. will not be liable for any losses you may incur during the period of self-exclusion in any form.',
    user_agreement_pp: 'Privacy Policy',
    user_agreement_pp_1:
      'You hereby acknowledge and accept that if we deem necessary, we are able to collect and otherwise use your personal data in order to allow you access and use of the Websites and in order to allow you to participate in the Games.',
    user_agreement_pp_2:
      'We hereby acknowledge that in collecting your personal details as stated in the previous provision, we are bound by the Data Protection Act. We will protect your personal information and respect your privacy in accordance with best business practices and applicable laws.',
    user_agreement_pp_3:
      'We will use your personal data to allow you to participate in the Games and to carry out operations relevant to your participation in the Games. We may also use your personal data to inform you of changes, new services and promotions that we think you may find interesting. If you do not wish to receive such direct marketing correspondences, you may opt out of the service.',
    user_agreement_pp_4:
      'Your personal data will not be disclosed to third parties, unless it is required by law. As DmWin business partners or suppliers or service providers may be responsible for certain parts of the overall functioning or operation of the Website, personal data may be disclosed to them. The employees of DmWin have access to your personal data for the purpose of executing their duties and providing you with the best possible assistance and service. You hereby consent to such disclosures.',
    user_agreement_pp_5:
      'We shall keep all information provided as personal data. You have the right to access personal data held by us about you. No data shall be destroyed unless required by law, or unless the information held is no longer required to be kept for the purpose of the relationship.',
    user_agreement_pp_6:
      'In order to make your visit to the Websites more user-friendly, to keep track of visits to the Websites and to improve the service, we collect a small piece of information sent from your browser, called a cookie. You can, if you wish, turn off the collection of cookies. You must note, however, that turning off cookies may severely restrict or completely hinder your use of the Websites.',
    user_agreement_cp: 'Cookies Policy',
    user_agreement_cp_1: '1.What are cookies?',
    user_agreement_cp_1_ans:
      "A cookie is a piece of information in the form of a very small text file that is placed on an internet user's computer. It is generated by a web page server (which is basically the computer that operates the website) and can be used by that server whenever the user visits the site. A cookie can be thought of as an internet user's identification card, which tells a website when the user has returned. Cookies can't harm your computer and we don't store any personally identifiable information about you on any of our cookies.",
    user_agreement_cp_2: '2.Why do we use cookies on DmWin?',
    user_agreement_cp_2_ans:
      'DmWin uses two types of cookies: cookies set by us and cookies set by third parties (i.e. other websites or services). DmWin cookies enable us to keep you signed in to your account throughout your visit and to tailor the information displayed on the site to your preferences.',
    user_agreement_cp_3: '3.What cookies do we use on DmWin?',
    user_agreement_cp_3_ans_1:
      'Below is a list of the main cookies set by DmWin, and what each is used for:',
    user_agreement_cp_3_ans_2: "_fp - stores browser's fingerprint. Lifetime: forever.",
    user_agreement_cp_3_ans_3:
      '_t - stores timestamp when user firstly visited site in current browsing session. Needed for unique visits statistic. Lifetime: browsing session.',
    user_agreement_cp_3_ans_4:
      '_r - stores http referrer for current browsing session. Needed in order to external track traffic sources. Lifetime: browsing session.',
    user_agreement_cp_3_ans_5:
      '_c - stores identifier of affiliate campaign. Needed for affiliate statistic. Lifetime: forever.',
    user_agreement_cp_3_ans_6: 'Cookies set by third parties for wildcard domain:',
    user_agreement_cp_3_ans_7: '*.DmWin',
    user_agreement_cp_3_ans_8: 'Google analytics: _ga, _gat, _gid',
    user_agreement_cp_3_ans_9: 'Zendesk：__ zlcmid',
    user_agreement_cp_3_ans_10: 'Cloudflare：__ cfuid',
    user_agreement_cp_3_ans_11:
      'Please keep in mind that some browsers (i.e. chrome on mac) keep background processes running even if no tabs opened due to this session cookies may left set between sessions.There are also cookies set by third party scripts to their domains.',
    user_agreement_cp_4: '4.How can I manage my cookies on DmWin?',
    user_agreement_cp_4_ans:
      'If you wish to stop accepting cookies, you can do so through the Privacy Settings option in your browser.',
    user_agreement_cp_5: '5.Personal Data Protection Policy',
    user_agreement_cp_5_ans:
      'DmWin’s mission is to keep your Data safe and for this matter we protect your data in various ways. We provide our customers with high security standards, such as encryption of data in motion over public networks, encryption of data in database, auditing standards, Distributed Denial of Service mitigations, and a Live Chat available on-site.',
    user_agreement_cp_6: '6.Server Protection Policy',
    user_agreement_cp_6_ans_1: 'All servers have full encryption;',
    user_agreement_cp_6_ans_2: 'All backups have encryption;',
    user_agreement_cp_6_ans_3: 'Firewalls, VPN Access;',
    user_agreement_cp_6_ans_4: 'Access to servers allowed only over VPN;',
    user_agreement_cp_6_ans_5: 'All http/s services work over Cloudflare;',
    user_agreement_cp_6_ans_6: 'Connections to nodes over VPN;',
    user_agreement_cp_6_ans_7: 'SSH port forwarding tunnels;',
    user_agreement_cp_6_ans_8: 'Services allowed only over VPN;',
    user_agreement_cp_6_ans_9: 'Server have firewall and allowed only SSH port;',
    user_agreement_cp_6_ans_10: 'Alerts on critical services.',
    user_agreement_cp_6_ans_11: 'Data Breach Notification',
    user_agreement_cp_6_ans_12:
      'When DmWin will be made aware of personal data breaches we will notify relevant users in accordance with GDPR timeframes.',
    user_agreement_cp_7: '7.Data International Transfer',
    user_agreement_cp_7_ans_1:
      'We only disclose personal data to third parties where it is necessary to provide the high-quality service or in order to respond lawful requests from authorities.',
    user_agreement_cp_7_ans_2:
      'We share the following data to third party systems:Zendesk Inc. – username and e-mail information is transferred if user sends a message to live-chat or sends an e-mail to support mailbox.',
    user_agreement_cp_7_ans_3:
      'Although we try to do our best, problems could occur now and then. Our team will do everything we could to solve your problems as soon as possible. To assist you quicker, You can join us by clicking the button above to join the telegram group.',
    user_agreement_cp_7_ans_4: 'If an error occurs, please provide the following information:',
    user_agreement_cp_7_ans_5: 'Username',
    user_agreement_cp_7_ans_6: 'Date and time of the problem',
    user_agreement_cp_7_ans_7: 'Game ID or table name, if any',
    user_agreement_cp_7_ans_8: 'Screenshot of the error, if possible',
    user_agreement_cp_7_ans_9:
      'We really appreciate your help and the error report you provided because your information report could help us improve.',
    user_agreement_cp_7_ans_10: 'Collecting and Using Your Personal Data',
    user_agreement_cp_7_ans_11: 'Types of Data Collected',
    user_agreement_cp_7_ans_12: 'Personal Data',
    user_agreement_cp_7_ans_13:
      'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
    user_agreement_cp_7_ans_14: 'Email address',
    user_agreement_cp_7_ans_15: 'First name and last name',
    user_agreement_cp_7_ans_16: 'Usage Data is collected automatically when using the Service.',
    user_agreement_cp_7_ans_17:
      "Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
    user_agreement_cp_7_ans_18:
      'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
    user_agreement_cp_7_ans_19:
      'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
    user_agreement_cp_7_ans_20: 'Information from Third-Party Social Media Services',
    user_agreement_cp_7_ans_21:
      'DmWin allows You to create an account and log in to use the Service through the following Third-party Social Media Services:',
    user_agreement_cp_7_ans_22: 'Google',
    user_agreement_cp_7_ans_23: 'Facebook',
    user_agreement_cp_7_ans_24: 'Telegram',
    user_agreement_cp_7_ans_25: 'Metamask',
    user_agreement_cp_7_ans_26: 'Web3.0',
    user_agreement_cp_7_ans_27:
      "If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.",
    user_agreement_cp_7_ans_28:
      "You may also have the option of sharing additional information with the DmWin through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving DmWin permission to use, share, and store it in a manner consistent with this Privacy Policy.",
    user_agreement_delete_personal_data: 'Delete Personal Data',
    user_agreement_dps_ans:
      'You can request to have your personal data deleted if DmWin no longer have a legal reason to continue to process or store it. Please note that this right is not guaranteed - in the sense that DmWin do not have the ability to comply with your request if it is subject to a legal obligation to store your data. You can request the deletion of your personal data by sending an email to support@DmWin.',
    user_agreement_registration_n_login: 'Registration and Login',
    user_agreement_rnl_ans_1:
      'You must be at least 18 years old to register. If you want to add your email address, please make sure the email address you entered is correct so that later it can be used in KYC account verification.',
    user_agreement_rnl_ans_2:
      'You can login at any time. For added security, we recommend you to add 2FA. To know more about Google authenticator.',
    user_agreement_rnl_ans_3:
      'If you need to change your registered email, we are so sorry, but we are not able to update this information. If you insist on changing your username and/or registered email, we suggest you close the current account and register a new one.',
  },
  policy: {
    policy_title: 'Privacy Policy',
    policy_term: ` <div class="policy_wrapper">
    <div>
      This privacy policy ("policy") will help you understand how DmWin ("us", "we",
      "our") uses and protects the data you provide to us when you visit and use [blog URL]
      ("blog", "service").
    </div>
    <div>
      We reserve the right to change this policy at any given time, of which you will be
      promptly updated. If you want to make sure that you are up to date with the latest
      changes, we advise you to frequently visit this page.
    </div>
    <br />
    <div>What User Data We Collect</div>
    <div>When you visit the blog, we may collect the following data:</div>
    <ul>
      <li>Your IP address.</li>
      <li>Your contact information and email address.</li>
      <li>Other information such as interests and preferences.</li>
      <li>Data profile regarding your online behavior on our blog.</li>
    </ul>
    <br />
    <div>Why We Collect Your Data</div>
    <div>
      We are collecting your data for several reasons:
      <ul>
        <li>To better understand your needs.</li>
        <li>To improve our services and products.</li>
        <li>
          To send you promotional emails containing the information we think you will find
          interesting.
        </li>
        <li>
          To contact you to fill out surveys and participate in other types of market
          research.
        </li>
        <li>
          To customize our blog according to your online behavior and personal preferences.
        </li>
      </ul>
    </div>
    <br />
    <div>Safeguarding and Securing the Data</div>
    <div>
      DmWin is committed to securing your data and keeping it confidential. DmWin has
      done all in its power to prevent data theft, unauthorized access, and disclosure by
      implementing the latest technologies and software, which help us safeguard all the
      information we collect online.
    </div>
    <br />
    <div> Our Cookie Policy</div>
    <div>
      Once you agree to allow our blog to use cookies, you also agree to use the data it
      collects regarding your online behavior (analyze web traffic, web pages you visit and
      spend the most time on). The data we collect by using cookies is used to customize our
      blog to your needs. After we use the data for statistical analysis, the data is
      completely removed from our systems.
    </div>
    <div>
      Please note that cookies don't allow us to gain control of your computer in any way.
      They are strictly used to monitor which pages you find useful and which you do not so
      that we can provide a better experience for you.
    </div>
    <div>
      If you want to disable cookies, you can do it by accessing the settings of your
      internet browser. You can visit <a href="https://www.internetcookies.com" target="_blank">
      https://www.internetcookies.com</a>, which contains comprehensive information on how to do this on a wide variety of
      browsers and devices.
    </div>
    <br />
    <div>Links to Other Websites</div>
    <div>
      Our blog contains links that lead to other websites. If you click on these links
      DmWin is not held responsible for your data and privacy protection. Visiting those
      websites is not governed by this privacy policy agreement. Make sure to read the
      privacy policy documentation of the website you go to from our website.
    </div>
    <br />
    <div>Restricting the Collection of your Personal Data</div>
    <div>
      At some point, you might wish to restrict the use and collection of your personal
      data. You can achieve this by doing the following:
    </div>
    <div>
      When you are filling the forms on the blog, make sure to check if there is a box which
      you can leave unchecked, if you don't want to disclose your personal information.
    </div>
    <div>
      If you have already agreed to share your information with us, feel free to contact us
      via email and we will be more than happy to change this for you.
    </div>
    <div>
      DmWin will not lease, sell or distribute your personal information to any third
      parties, unless we have your permission. We might do so if the law forces us. Your
      personal information will be used when we need to send you promotional materials if
      you agree to this privacy policy.
    </div>
    <br />
    <div>
      PRO TIP: Save your time and take the guesswork out of the legal jargon with the
      professional privacy policy generator trusted by thousands of businesses.
    </div>
  </div>`,
  },
  term_service: {
    title: 'Terms of Service',
    term: `<div class="term_wrapper">
    <div>
      Meta builds technologies and services that enable people to connect with each other, build communities, 
      and grow businesses. These Terms govern your use of Facebook, Messenger, 
      and the other products, features, apps, services, technologies, and software we offer (the Meta Products or Products), 
      except where we expressly state that separate terms (and not these) apply. These Products are provided to you by Meta Platforms, Inc.
    </div>
    <div>
      We don’t charge you to use Facebook or the other products and services covered by these Terms, 
      unless we state otherwise. Instead, businesses and organizations, and other persons pay us to show you ads for their products and services. 
      By using our Products, you agree that we can show you ads that we think may be relevant to you and your interests. 
      We use your personal data to help determine which personalized ads to show you.
    </div>
    <div>
      We don’t sell your personal data to advertisers, and we don’t share information that directly identifies you 
      (such as your name, email address or other contact information) with advertisers unless you give us specific permission. Instead, 
      advertisers can tell us things like the kind of audience they want to see their ads, and we show those ads to people who may be interested. 
      We provide advertisers with reports about the performance of their ads that help them understand how people are interacting with their content. 
      See Section 2 below to learn more about how personalized advertising under these terms works on the Meta Products.
    </div>
    <div>
      Our Privacy Policy explains how we collect and use your personal data to determine some of the ads you see and provide all of the other services described below. 
      You can also go to your settings pages of the relevant Meta Product at any time to review the privacy choices you have about how we use your data.
    </div>
    <br />
    <div>1. The services we provide</div>
    <div>
      Our mission is to give people the power to build community and bring the world closer together. To help advance this mission, 
      we provide the Products and services described below to you:
    </div>
    <div>
      Provide a personalized experience for you:
    </div>
    <div>
      Your experience on Facebook is unlike anyone else's: from the posts, stories, events, ads, 
      and other content you see in Facebook News Feed or our video platform to the Facebook Pages you follow and other features you might use, 
      such as Facebook Marketplace, and search. For example, we use data about the connections you make, the choices and settings you select, 
      and what you share and do on and off our Products - to personalize your experience.
    </div>
    <div>
      Connect you with people and organizations you care about:
    </div>
    <div>
      We help you find and connect with people, groups, businesses, organizations, and others that matter to you across the Meta Products you use. 
      We use data to make suggestions for you and others - for example, groups to join, events to attend, Facebook Pages to follow or send a message to, 
      shows to watch, and people you may want to become friends with. Stronger ties make for better communities, 
      and we believe our services are most useful when people are connected to people, groups, and organizations they care about.
    </div>
    <div>
      Empower you to express yourself and communicate about what matters to you:
    </div>
    <div>
      There are many ways to express yourself on Facebook to communicate with friends, 
      family, and others about what matters to you - for example, sharing status updates, photos, videos, 
      and stories across the Meta Products (consistent with your settings), sending messages or making voice or video calls to a friend or several people, 
      creating events or groups, or adding content to your profile as well as showing you insights on how others engage with your content. 
      We have also developed, and continue to explore, new ways for people to use technology, 
      such as augmented reality and 360 video to create and share more expressive and engaging content on Meta Products.
    </div>
    <div>
      Help you discover content, products, and services that may interest you:
    </div>
    <div>
      We show you personalized ads, offers, and other sponsored or commercial content to help you discover content, products, 
      and services that are offered by the many businesses and organizations that use Facebook and other Meta Products. Section 2 below explains this in more detail.
    </div>
    <div>
      Promote the safety, security, and integrity of our services, combat harmful conduct and keep our community of users safe:
    </div>
    <div>
      People will only build community on Meta Products if they feel safe and secure. We work hard to maintain the security 
      (including the availability, authenticity, integrity, and confidentiality) of our Products and services. We employ dedicated teams around the world, 
      work with external service providers, partners and other relevant entities and develop advanced technical systems to detect potential misuse of our Products, 
      harmful conduct towards others, and situations where we may be able to help support or protect our community, 
      including to respond to user reports of potentially violating content. If we learn of content or conduct like this, 
      we may take appropriate action based on our assessment that may include - notifying you, offering help, removing content, 
      removing or restricting access to certain features, disabling an account, or contacting law enforcement. 
      We share data across Meta Companies when we detect misuse or harmful conduct by someone using one of our Products or to help keep Meta Products, 
      users and the community safe. For example, we share information with Meta Companies that provide financial products and services to help them promote safety, 
      security and integrity and comply with applicable law. Meta may access, preserve, 
      use and share any information it collects about you where it has a good faith belief it is required or permitted by law to do so. For more information, 
      please review our Privacy Policy.
    </div>
    <div>
      In some cases, the Oversight Board may review our decisions, subject to its terms and bylaws. Learn more here.
    </div>
    <div>
      Use and develop advanced technologies to provide safe and functional services for everyone:
    </div>
    <div>
      We use and develop advanced technologies - such as artificial intelligence, machine learning systems, and augmented reality - 
      so that people can use our Products safely regardless of physical ability or geographic location. 
      For example, technology like this helps people who have visual impairments understand what or who is in photos or videos shared on Facebook or Instagram. 
      We also build sophisticated network and communication technology to help more people connect to the internet in areas with limited access. 
      And we develop automated systems to improve our ability to detect and remove abusive and dangerous activity that may harm our community and the integrity of our Products.
    </div>
    <div>
      Research ways to make our services better:
    </div>
    <div>
      We engage in research to develop, test, and improve our Products. This includes analyzing data we have about our users and understanding how people use our Products, 
      for example by conducting surveys and testing and troubleshooting new features. 
      Our Privacy Policy explains how we use data to support this research for the purposes of developing and improving our services.
    </div>
    <div>
      Provide consistent and seamless experiences across the Meta Company Products:
    </div>
    <div>
      Our Products help you find and connect with people, groups, businesses, organizations, and others that are important to you. 
      We design our systems so that your experience is consistent and seamless across the different Meta Company Products that you use. 
      For example, we use data about the people you engage with on Facebook to make it easier for you to connect with them on Instagram or Messenger, 
      and we enable you to communicate with a business you follow on Facebook through Messenger.
    </div>
    <div>
      Ensuring access to our services:
    </div>
    <div>
      To operate our global services and enable you to connect with people around the world, we need to transfer, 
      store and distribute content and data to our data centers, partners, service providers, vendors and systems around the world, 
      including outside your country of residence. The use of this global infrastructure is necessary and essential to provide our services. 
      This infrastructure may be owned, operated, or controlled by Meta Platforms, Inc., Meta Platforms Ireland Limited, or its affiliates.
    </div>
    <br/>
    <div>
      2. How our services are funded
    </div>
    <div>
      Instead of paying to use Facebook and the other products and services we offer, by using the Meta Products covered by these Terms, 
      you agree that we can show you personalized ads and other commercial and sponsored content that businesses and organizations pay us to promote on and off Meta Company Products. 
      We use your personal data, such as information about your activity and interests, to show you personalized ads and sponsored content that may be more relevant to you.
    </div>
    <div>
      Protecting people's privacy is central to how we've designed our personalized ads system. 
      This means that we can show you relevant and useful ads without telling advertisers who you are. 
      We don't sell your personal data. We allow advertisers to tell us things like their business goal, 
      and the kind of audience they want to see their ads (for example, people between the age of 18-35 who like cycling). 
      We then show their ad to people who we think might be interested.
    </div>
    <div>
      We also provide advertisers with reports about the performance of their ads to help them understand how people are interacting with their content on and off Meta Products. 
      For example, we provide general demographic and interest information to advertisers to help them better understand their audience, 
      like the fact that women between the ages of 25 and 34 who live in Madrid and like software engineering have seen an ad. 
      We don’t share information that directly identifies you (information such as your name or email address that by itself can be used to contact you or identifies who you are) 
      unless you give us specific permission. Learn more about how Meta ads work here.
    </div>
    <div>
      We collect and use your personal data in order to provide the services described above to you. You can learn about how we collect and use your data in our Privacy Policy. 
      You have controls over the types of ads and advertisers you see, and the types of information we use to determine which ads we show you. Learn more.
    </div>
    <br/>
    <div>
      3. Your commitments to Facebook and our community
    </div>
    <div>
      We provide these services to you and others to help advance our mission. In exchange, we need you to make the following commitments:
    </div>
    <div>
      3.1. Who can use Facebook
    </div>
    <div>
      When people stand behind their opinions and actions, our community is safer and more accountable. For this reason, you must:
    </div>
    <div>
      Provide for your account the same name that you use in everyday life.
    </div>
    <div>
      Provide accurate information about yourself.
    </div>
    <div>
      Create only one account (your own) and use it for personal purposes.
    </div>
    <div>
      Not share your password, give access to your Facebook account to others, or transfer your account to anyone else (without our permission).
    </div>
    <div>
      We try to make Facebook broadly available to everyone, but you cannot use Facebook if:
    </div>
    <div>
      You are under 13 years old.
    </div>
    <div>
      You are a convicted sex offender.
    </div>
    <div>
      We've previously disabled your account for violations of our Terms or the Community Standards, or other terms and policies that apply to your use of Facebook. If we disable your account for a violation of our Terms, the Community Standards, or other terms and policies, you agree not to create another account without our permission. 
      Receiving permission to create a new account is provided at our sole discretion, and does not mean or imply that the disciplinary action was wrong or without cause.
    </div>
    <div>
      You are prohibited from receiving our products, services, or software under applicable laws.
    </div>
    <div>
      3.2. What you can share and do on Meta Products
    </div>
    <div>
      We want people to use Meta Products to express themselves and to share content that is important to them, but not at the expense of the safety and well-being of others or the integrity of our community. 
      You therefore agree not to engage in the conduct described below (or to facilitate or support others in doing so):
    </div>
    <div>
      You may not use our Products to do or share anything:
    </div>
    <div>
      That violates these Terms, the Community Standards, or other terms and policies that apply to your use of our Products.
    </div>
    <div>
      That is unlawful, misleading, discriminatory or fraudulent (or assists someone else in using our Products in such a way).
    </div>
    <div>
      That you do not own or have the necessary rights to share.
    </div>
    <div>
      That infringes or violates someone else's rights, including their intellectual property rights (such as by infringing another’s copyright or trademark, 
        or distributing or selling counterfeit or pirated goods), unless an exception or limitation applies under applicable law.
    </div>
    <div>
      You may not upload viruses or malicious code, use the services to send spam, or do anything else that could disable, overburden, interfere with, 
      or impair the proper working, integrity, operation, or appearance of our services, systemes, or Products.
    </div>
    <div>
      You may not access or collect data from our Products using automated means (without our prior permission) or attempt to access data you do not have permission to access.
    </div>
    <div>
      You may not proxy, request, or collect Product usernames or passwords, or misappropriate access tokens.
    </div>
    <div>
      You may not sell, license, or purchase any data obtained from us or our services, except as provided in the Platform Terms.
    </div>
    <div>
      You may not misuse any reporting, flagging, dispute, or appeals channel, such as by making fraudulent, duplicative, or groundless reports or appeals.
    </div>
    <div>
      We can remove or restrict access to content that is in violation of these provisions. We can also suspend or disable your account for conduct that violates these provisions, as provided in Section 4.B.
    </div>
    <div>
      If we remove content that you have shared in violation of the Community Standards, we’ll let you know and explain any options you have to request another review, 
      unless you seriously or repeatedly violate these Terms or if doing so may expose us or others to legal liability; harm our community of users; 
      compromise or interfere with the integrity or operation of any of our services, systems or Products; where we are restricted due to technical limitations; 
      or where we are prohibited from doing so for legal reasons. For information on account suspension or termination, see Section 4.B below.
    </div>
    <div>
      To help support our community, we encourage you to report content or conduct that you believe violates your rights (including intellectual property rights) or our terms and policies, if this feature exists in your jurisdiction.
    </div>
    <div>
      We also can remove or restrict access to content features, services, or information if we determine that doing so is reasonably necessary to avoid or mitigate misuse of our services or adverse legal or regulatory impacts to Meta.
    </div>
    <div>
      3.3. The permissions you give us
    </div>
    <div>
      We need certain permissions from you to provide our services:
    </div>
    <div>
      Permission to use content you create and share: Some content that you share or upload, such as photos or videos, may be protected by intellectual property laws.
    </div>
    <div>
      You retain ownership of the intellectual property rights (things like copyright or trademarks) in any such content that you create and share on Facebook and other Meta Company Products you use. Nothing in these Terms takes away the rights you have to your own content. You are free to share your content with anyone else, wherever you want.
    </div>
    <div>
      However, to provide our services we need you to give us some legal permissions (known as a "license") to use this content. 
      This is solely for the purposes of providing and improving our Products and services as described in Section 1 above.
    </div>
    <div>
      Specifically, when you share, post, or upload content that is covered by intellectual property rights on or in connection with our Products, 
      you grant us a non-exclusive, transferable, sub-licensable, royalty-free, and worldwide license to host, use, distribute, modify, run, copy, 
      publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). 
      This means, for example, that if you share a photo on Facebook, you give us permission to store, copy, 
      and share it with others (again, consistent with your settings) such as Meta Products or service providers that support those products and services. 
      This license will end when your content is deleted from our systems.
    </div>
    <div>
      You can delete individual content you share, post, and upload at any time. In addition, all content posted to your personal account will be deleted if you delete your account. Learn more about how to delete your account. Account deletion does not automatically delete content that you post as an admin of a page or content that you create collectively with other users, such as photos in Shared Albums which may continue to be visible to other album members.
    </div>
    <div>
      It may take up to 90 days to delete content after we begin the account deletion process or receive a content deletion request. If you send content to trash, the deletion process will automatically begin in 30 days unless you chose to delete the content sooner. While the deletion process for such content is being undertaken, the content is no longer visible to other users. After the content is deleted, it may take us up to another 90 days to remove it from backups and disaster recovery systems.
    </div>
    <div>
      Content will not be deleted within 90 days of the account deletion or content deletion process beginning in the following situations
      where your content has been used by others in accordance with this license and they have not deleted it (in which case this license will continue to apply until that content is deleted);
      where deletion within 90 days is not possible due to technical limitations of our systems, in which case, we will complete the deletion as soon as technically feasible; or
      where immediate deletion would restrict our ability to:
    </div>
    <div>
      investigate or identify illegal activity or violations of our terms and policies (for example, to identify or investigate misuse of our Products or systems);
    </div>
    <div>
      protect the safety, integrity, and security of our Products, systems, services, our employees, and users, and to defend ourselves;
      comply with legal obligations for the preservation of evidence, including data Meta Companies providing financial products and services preserve to comply with any record keeping obligations required by law; or
      comply with a request of a judicial or administrative authority, law enforcement or a government agency;
    </div>
    <div>
      in which case, the content will be retained for no longer than is necessary for the purposes for which it has been retained (the exact duration will vary on a case-by-case basis).
      In each of the above cases, this license will continue until the content has been fully deleted.
      Permission to use your name, profile picture, and information about your actions with ads and sponsored or commercial content: You give us permission to use your name and profile picture and information about actions you have taken on Facebook next to or in connection with ads, offers, and other sponsored or commercial content that we display across our Products, without any compensation to you. For example, we may show your friends that you are interested in an advertised event or have liked a Facebook Page created by a brand that has paid us to display its ads on Facebook. Ads and content like this can be seen only by people who have your permission to see the actions you've taken on Meta Products. You can learn more about your ad settings and preferences. 
      Permission to update software you use or download: If you download or use our software, you give us permission to download and install updates to the software where available.
    </div>
    <div>
      3.4. Limits on using our intellectual property
    </div>
    <div>
      If you use content covered by intellectual property rights that we have and make available in our Products (for example, images, designs, videos, or sounds we provide that you add to content you create or share on Facebook), we retain all rights to that content (but not yours). 
      You can only use our copyrights or trademarks (or any similar marks) as expressly permitted by our Brand Usage Guidelines or with our prior written permission. You must obtain our written permission (or permission under an open source license) to modify, translate, create derivative works of, decompile, 
      or reverse engineer our products or their components, or otherwise attempt to extract source code from us, 
      unless an exception or limitation applies under applicable law or your conduct relates to the Meta Bug Bounty Program.
    </div>
    <br/>
    <div>
      4. Additional provisions
    </div>
    <div>
      4.1. Updating our Terms
    </div>
    <div>
      We work constantly to improve our services and develop new features to make our Products better for you and our community. As a result, we may need to update these 
      Terms from time to time to accurately reflect our services and practices, to promote a safe and secure experience on our Products and services, 
      and/or to comply with applicable law. We will only make any changes if the provisions are no longer appropriate or if they are incomplete, 
      and only if the changes are reasonable and take due account of your interests or if the changes are required for safety and security purposes or to comply with applicable law.
    </div>
    <div>
      We will notify you (for example, by email or through our Products) at least 30 days before we make changes to these Terms and give you an opportunity to review them before they go into effect, 
      unless changes are required by law. Once any updated Terms are in effect, 
      you will be bound by them if you continue to use our Products.
      We hope that you will continue using our Products, but if you do not agree to our updated Terms and no longer want to be a part of the Meta community, 
      you can delete your account at any time.
    </div>
    <div>
      4.2. Account suspension or termination
    </div>
    <div>
      We want Facebook to be a place where people feel welcome and safe to express themselves and share their thoughts and ideas.
      If we determine, in our discretion, that you have clearly, seriously or repeatedly breached our Terms or Policies, including in particular the Community Standards, we may suspend or permanently disable your access to Meta Company Products, 
      and we may permanently disable or delete your account. We may also disable or delete your account if you repeatedly infringe other people’s intellectual property rights or where we are required to do so for legal reasons.
    </div>
    <div>
      We may disable or delete your account if after registration your account is not confirmed, your account is unused and remains inactive for an extended period of time, or if we detect someone may have used it without your permission and we are unable to confirm your ownership of the account. Learn more about how we disable and delete accounts.
    </div>
    <div>
      Where we take such action we’ll let you know and explain any options you have to request a review, unless doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or Products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.
    </div>
    <div>
      You can learn more about what you can do if your account has been disabled and how to contact us if you think we have disabled your account by mistake.
    </div>
    <div>
      If you delete or we disable or delete your account, these Terms shall terminate as an agreement between you and us, but the following provisions will remain in place: 3, 4.2-4.5.
    </div>
    <div>
      4.3. Limits on liability
    </div>
    <div>
      We work hard to provide the best Products we can and to specify clear guidelines for everyone who uses them. Our Products, however, are provided "as is," 
      and to the extent permissible by law, we make no guarantees that they always will be safe, secure, or error-free, or that they will function without disruptions, 
      delays, or imperfections. To the extent permitted by law, we also DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, 
      INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. 
      We do not control or direct what people and others do or say, and we are not responsible for their actions or conduct (whether online or offline) or any content they share 
      (including offensive, inappropriate, obscene, unlawful, and other objectionable content).
    </div>
    <div>
      We cannot predict when issues might arise with our Products. Accordingly, our liability shall be limited to the fullest extent permitted by applicable law. 
      To the fullest extent permitted by applicable law under no circumstance will we be liable to you for any lost profits, revenues, information, or data, 
      or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms or the Meta Products 
      (however caused and on any theory of liability, including negligence), even if we have been advised of the possibility of such damages.
    </div>
    <div>
      4.4. Disputes
    </div>
    <div>
      We try to provide clear rules so that we can limit or hopefully avoid disputes between you and us. If a dispute does arise, however, 
      it's useful to know up front where it can be resolved and what laws will apply.
      If you are a consumer, the laws of the country in which you reside will apply to any claim, cause of action, 
      or dispute you have against us that arises out of or relates to these Terms or the Meta Products, 
      and you may resolve your claim in any competent court in that country that has jurisdiction over the claim. 
      In all other cases, and for any claim, cause of action, or dispute that Meta files against you, you and Meta agree that any such claim, 
      cause of action or dispute must be resolved exclusively in the U.S. District Court for the Northern District of California or a state court located in San Mateo County. 
      You also agree that you submit to the personal jurisdiction of either of these courts for the purpose of litigating any such claim, and that the laws of the State of California will govern these Terms and any claim, 
      cause of action, or dispute without regard to conflict of law provisions. Without prejudice to the foregoing, you agree that, in its sole discretion, Meta may bring any claim, cause of action, 
      or dispute we have against you in any competent court in the country in which you reside that has jurisdiction over the claim.
    </div>
    <div>
      4.5. Other
    </div>
    <div>
      These Terms (formerly known as the Statement of Rights and Responsibilities) make up the entire agreement between you and Meta Platforms, Inc. regarding your use of our Products. 
      They supersede any prior agreements.
    </div>
    <div>
      Some of the Products we offer are also governed by supplemental terms. If you use any of those Products, supplemental terms will be made available and will become part of our agreement with you. 
      For instance, if you access or use our Products for commercial or business purposes, such as buying ads, selling products, developing apps, 
      managing a group or Page for your business, or using our measurement services, you must agree to our Commercial Terms. If you post or share content containing music,
      you must comply with our Music Guidelines. To the extent any supplemental terms conflict with these Terms, the supplemental terms shall govern to the extent of the conflict.
    </div>
    <div>
      If any portion of these Terms is found to be unenforceable, the unenforceable portion will be deemed amended to the minimum extent necessary to make it enforceable, and if it can’t be made enforceable, 
      then it will be severed and the remaining portion will remain in full force and effect. If we fail to enforce any of these Terms, it will not be considered a waiver. 
      Any amendment to or waiver of these Terms must be made in writing and signed by us.
    </div>
    <div>
      You will not transfer any of your rights or obligations under these Terms to anyone else without our consent.
    </div>
    <div>
      You may designate a person (called a legacy contact) to manage your account if it is memorialized. If you enable it in your settings, 
      only your legacy contact or a person who you have identified in a valid will or similar legal document expressing clear consent to disclose your content to that person upon death or incapacity will be able to seek limited disclosure of information from your account after it is memorialized.
    </div>
    <div>
      These Terms do not confer any third-party beneficiary rights. All of our rights and obligations under these Terms are freely assignable by us in connection with a merger, acquisition, or sale of assets, or by operation of law or otherwise.
    </div>
    <div>
      We may change the username for your account in certain circumstances (for example, if someone else claims the username and it appears unrelated to the name you use in everyday life).
    </div>
    <div>
      We always appreciate your feedback and other suggestions about our products and services. But we may use feedback and other suggestions without any restriction or obligation to compensate you, and we are under no obligation to keep them confidential.
      We reserve all rights not expressly granted to you.
    </div>
    <br/>
    <div>
      5. Other terms and policies that may apply to you
    </div>
    <div>
      Community Standards: These guidelines outline our standards regarding the content you post to Facebook and your activity on Facebook and other Meta Products.
    </div>
    <div>
      Commercial Terms: These terms apply if you also access or use our Products for any commercial or business purpose, including advertising, operating an app on our Platform, using our measurement services, managing a group or a Page for a business, or selling goods or services.
    </div>
    <div>
      Community Payment Terms: These terms apply to payments made on or through Meta Products.
    </div>
    <div>
      Commerce Policies: These guidelines outline the policies that apply when you offer products or services for sale on Facebook, Instagram, and WhatsApp.
    </div>
    <div>
      Music Guidelines: These guidelines outline the policies that apply if you post or share content containing music on any Meta Products.
      Advertising Policies: These policies apply to partners who advertise across the Meta Products and specify what types of ad content are allowed by partners who advertise across the Meta Products.
    </div>
    <div>
      Self-Serve Ad Terms: These terms apply when you use self-serve advertising interfaces to create, submit, or deliver advertising or other commercial or sponsored activity or content.
    </div>
    <div>
      Facebook Pages, Groups and Events Policy: These guidelines apply if you create or administer a Facebook Page, group, or event, or if you use Facebook to communicate or administer a promotion.
    </div>
    <div>
      Meta Platform Policy: These terms apply to the use of the set of APIs, SDKs, tools, plugins, code, technology, content, and services that enables others to develop functionality, retrieve data from MetaProducts, or provide data to us.
    </div>
    <div>
      Developer Payment Terms: These terms apply to developers of applications that use Facebook Payments.
    </div>
    <div>
      Meta Brand Resources: These guidelines outline the policies that apply to use of Meta trademarks, logos, and screenshots.
    </div>
    <div>
      Recommendations Guidelines: The Facebook Recommendations Guidelines and Instagram Recommendations Guidelines outline our standards for recommending and not recommending content.
    </div>
    <div>
      Live Policies: These policies apply to all content broadcast to Facebook Live.
    </div>`,
  },
  datadeletion: {
    content: `<div class="dd_wrapper">
    <div>
      DmWin is just a web base application and we do not save your personal data in our server. According to the Facebook Platform rules, 
      we have to provide User Data Deletion Callback URL or 
      Data Deletion Instructions URL. If you want to delete your activities for the FinMonster, following these instructions:
    </div>
    <br />
    <div>
      1.   Go to Your Facebook Account's Setting & Privacy. Click " Setting ".
    </div>
    <br />
    <div>
      2.   Then, go to " Apps and Websites" and you will see all of your Apps activities.
    </div>
    <br />
    <div>
      3.   Select the option box for DmWin Production
    </div>
    <br />
    <div>
      4.   Click " Remove" button.
    </div>`,
  },
  deposit: {
    select_payment: 'Select Payment Method',
    make_deposit: 'Make Deposit',
    make_deposit_title: 'Please choose or enter deposit amount',
    enter_amount: 'Enter amount',
    min_deposit: 'Min. deposit',
    max_deposit: 'Max. deposit',
    deposit_btn: 'DEPOSIT',
    all: 'All',
    all_bonus: 'All Bonus',
    use_now: 'Use Now',
    select_bonus: 'Select Bonus',
    claim_bonus: 'Claim your bonus NOW!',
    label: 'Deposit',
    select_payment_method: 'Please select your payment method',
    valid_amount: 'Please fill in valid deposit amount',
    out_range: 'Deposit amount out of transaction range',
    unavailable_bonus: 'You are not eligible for this bonus, please check the requirement',
  },
  transaction: {
    tran_record: 'Transaction Records',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    betting: 'Bettings',
    records: 'Records',
    others: 'Others',
    bonus: 'Bonus',
    adjustment: 'Adjustment',
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected',
    add: 'Add',
    deduct: 'Deduct',
    freechip_bonus: 'FreechipBonus',
    reload_bonus: 'ReloadBonus',
    member_bonus: 'MemberBonus',
    bonus_rebate: 'BonusRebate',
    bonus_expired: 'BonusExpired',
    to: 'to',
    total: 'Total:',
    total_bet: 'Total Bet:',
    total_winloss: ' Total Win/Loss:',
    bet: 'Bet',
    winLoss: 'Win/Loss',
    win_status: 'WIN',
    draw_status: 'DRAW',
    lose_status: 'LOSE',
    bet_status: 'BET',
    cancel_status: 'CANCELLED',
    void_status: 'VOID',
    status_pending: 'In Processing',
    status_success: 'Successful',
    status_failed: 'Rejected',
    bonus_status1: 'On-going',
    bonus_status2: 'Finished',
    bonus_status3: 'Expired',
    used: 'Used',
  },
  profile: {
    alertTitle1: 'Complete Personal Detail',
    alertContent1: 'Please fill in your incomplete information.',
    title1: 'Personal Detail',
    username: 'UserName',
    dob: 'Date of Birth',
    email: 'Email',
    verify_btn: 'Verify',
    set_dob_btn: 'SET DATE OF BIRTH',
    your_dob_is: 'Your date of birth is',
    confirm_btn: 'CONFIRM',
    cancel_btn: 'CANCEL',
    confirmation: 'Confirmation',
    edit_avatar: 'Edit Avatar',
    modify_profile: 'Modify Profile',
    nickname: 'Nickname',
    nickname_validate: 'Do not use special character, otherwise your account will be locked.',
    change_ps: 'Change Password',
    old_ps: 'Old Password',
    new_ps: 'New Password',
    confirm_ps: 'Re-enter Password',
    modify_btn: 'MODIFY',
    update_success: 'Update Successfully.',
    remove_success: 'Remove Successfully.',
    remove_failed: 'Remove Failed.',
    email_verify: 'Email Verify',
    verify_email_label: 'Verify Email',
    verify_phone_label: 'Verify Phone Number',
    email_text:
      'We have sent an email with a verification link to your email address. Please verify.',
    confirm_birthday: 'Confrim Birthday',
    birth_text: 'Your birthday has been updated successfully.',
    set_dob: 'Please set your date of birth',
    invalid_email: 'This email address is invalid. Enter a new email address and try again',
    empty_email: 'Please fill in a valid email address.',
    invalid_phone: 'This phone number is invalid. Enter a new phone number and try again',
    empty_phone: 'Please fill in a valid phone number.',
    empty_veri_code: 'Please fill in verification code.',
    invalid_veri_code: 'Please make sure your phone number is verified.',
    verify_label: 'Verify',
    email_addrss: 'Email Address',
    member_center: 'Member Center',
    personal_detail: 'Personal Detail',
    change_pass: 'Change Password',
    funding: 'Funding',
    change_pass_title1:
      'Your login password will be used for withdrawals, please ensure a strong password.',
    update_data: 'Update Data',
    verify_title1: 'Please complete account verification to unlock more feature.',
    valid_nickname: 'Please enter maximum 20 alphanumeric characters',
    funding_add_card: 'Funding Add Bank Card',
    funding_title1:
      'We provide one bank card that can be bound. After the bank card binding is completed, withdrawals can only be made with this fiat currency.',
    bank_details: 'Bank Details',
    bank_notify_user: 'Please provide complete and correct BANK details.',
    usdt_notify_user: 'Please provide complete and correct USDT details.',
    ifsc_code: 'IFSC Code',
    bank_num: 'Bank Number',
    login_pass: 'Login Password',
    alert: 'ALERT',
    add_bank_success: 'Add Bank Account Successfully',
    ifsc_valid: 'Invalid IFSC. IFSC format: ABCD0123456',
    delete_bank_success: 'Bank Account has been deleted',
    acc_name: 'Account Name',
    acc_no_valid: 'Invalid Account No: Fill in number only.',
    delete_bank:
      'Are you sure you want to remove your account number? Once it has been removed, the account number cannot be used again unless you contact CS for help.',
    delete_phone:
      'Are you sure you want to remove your phone number? Once it has been removed, the phone number cannot be used again unless you contact CS for help.',
    delete_email:
      'Are you sure you want to remove your email address? Once it has been removed, the email address cannot be used again unless you contact CS for help.',
    delete_verify_password: 'Please enter your password to ensure you are the account holder',
    acc_name_valid: 'Invalid Account Name: Fill in alphabet characters only.',
    add: 'Add',
    blackListPhone:
      'This phone number has been restricted. Please contact CS for more information.',
    blackListBankAccount:
      'This account number has been restricted. Please contact CS for more information.',
    blackListEmail: 'This email has been restricted. Please contact CS for more information.',
  },
  slots: {
    search_placeholder: 'Game Name or Provider',
    all: 'All',
    all_provider: 'All Provider',
    provider: 'Provider',
    no_provider: 'No Provider',
    provider_not_found: 'Provider not found',
    clear_all: 'Clear All',
    recommend_head: 'Recommended for you',
    search_head: 'Search Result',
    nomore_game: 'No More Games',
    more_game: 'More Games',
    search_provider_placeholder: 'Provider',
  },
  withdrawal: {
    verify: 'Verify',
    verify_email_phonme_warning:
      'You need to verify your mobile phone and email before withdrawing.',
    verify_email_warning: 'You need to verify your email before withdrawing',
    verify_phone_warning: 'You need to verify your mobile phone before withdrawing',
    withdraw: 'Withdraw',
    funding: 'Funding',
    funding_warning: 'You need to complete the binding of at least a bank account before withdraw.',
    bind_now: 'Bind Now',
    title: 'Withdrawal',
    choose_method: 'Choose a withdrawal method',
    information: 'Withdrawal Information',
    choose_bank: 'Choose a bank card to withdraw money',
    ifsc_code: 'IFSC Code',
    card_number: 'Card Number',
    holder_name: "Holder's Name",
    account_number: 'Account Number',
    account_name: 'Account Name',
    invalid_upi_account: 'Invalid UPI account. UPI format',
    invalid_account_name: 'Invalid Account Name: Not allowed to enter number',
    account_name_required: 'Invalid Account Name: Fill in account name',
    wager_requirement: 'Wager Requirement',
    meet_wager_requirement:
      'You need to meet the wager requirements before you can make a withdrawal',
    current_bet_amount: 'Current Bet Amount',
    withdraw_amount: 'Withdraw Amount',
    valid_amount: 'Please fill in valid withdraw amount',
    out_range: 'Withdraw amount out of transaction range',
    min_withdrawal: 'Min. withdraw',
    max_withdrawal: 'Max. withdraw',
    text1:
      'Dear player! In order to avoid fraud you need to wager the amount of your deposit at least one time placing bets with the odd equivalent 1.01 or higher. Exactly the amount for which you have placed bets will be available for you for withdrawal. Once you have placed bets on the amount of your deposit, you can withdraw as much money as you want.',
    text2:
      "Processing time for withdrawal up to 24 hours after you clicked 'withdraw', please wait for payment",
  },
  affiliate: {
    title: 'Affiliate',
    overview: 'Overview',
    retention_program: 'Retention Program',
    referred_users: 'Referred Users',
    commission: 'Commission',
    overview_text1:
      'Recommend our brand and earn money by joining the DmWin affiliate! Every user who uses your link to register on DmWin and play games will bring you commission fees.',
    overview_text2:
      'Being a DmWin affiliate member means you will earn a commission on all your bets at our casino - unlike traditional affiliate programs, in this program, you will still earn the same commission whether you win or lose',
    invite_code_link: 'Invitation Code Link',
    program_text1:
      'At DmWin, first priority has always been and will always be player satisfaction. This is the core of our long term strategy for our users, interacting with the platform as players and as affiliates. We strive to achieve player satisfaction through unique programs and campaigns that reward our players. These programs provide additional funds and incentives to our players which not only provides for a better gambling experience but also contributes to increasing your overall affiliate commission earnings.',
    program_text2: 'These programs include:',
    rakeback: 'Rakeback',
    weekly_bonus: 'Weekly Bonus',
    monthly_bonus: 'Monthly Bonus',
    daily_reload: 'Hourly / Daily reload',
    vip_rewards: 'VIP level rewards',
    program_text3:
      'With this unique approach, your commission is based off of the house edge after these costs have been realised. Depending on the extent of what players receive as benefits, the house edge commission you receive will come out to roughly 45% less of the house edge advertised on any of our games.',
    program_text4:
      'If you would like a detailed breakdown on how these costs are calculated, feel free to contact support to be put in touch with an affiliate manager.',
    program_text5:
      'The retention program is utilised for your affiliates on both the Sportsbook and Casino, however the costs are already absorbed before calculating the affiliate commission on Sportsbook bets so that we can set a standard rate of 3% assumed margin.',
    register: 'Register:',
    first_deposit_time: 'First Deposit Time:',
    first_deposit_amount: 'First Deposit Amount:',
    deposit: 'Deposit:',
    total_bet_amount: 'Total Bet Amount:',
    total_winloss: ' Total Win/Loss:',
    ggr: 'GGR:',
    ngr: 'NGR:',
  },
  affiliateNew: {
    details: 'Details',
    Register: 'Register',
    total: 'Total',
    Deposit: 'Deposit',
    Sports: 'Sport',
    Casino: 'Casino',
    Slot: 'Slot',
    Poker: 'Poker',
    Original: 'Original',
    commission: 'Commission',
    GGR: 'GGR',
    NGR: 'NGR',
    'Reg Date': 'Reg Date',
    link_title: 'Earn commissions, start here',
    referral_link: 'Referral Link',
    share: 'Share',
    pfm_title: 'Last month performance',
    pfm_title_rebate: 'Turnover of the yesterday',
    pfm_title_daily: "Today's performance",
    commission_title: 'Last period commission',
    apply_agent: 'Apply to become our Agent',
    Active: 'Active',
    Turnover: 'Total Turnover',
    'Previous Period Negative GGR': 'Previous Period Negative GGR',
    team_details: 'Team Details',
    level_details: 'Level Details',
    level_details_title: 'Level Betting Details',
    history: 'History',
    list: 'List',
    Number: 'Number',
    Status: 'Status',
    Lottery: 'Lottery',
    select_level: 'Select Level',
    level1: 'ONE',
    level2: 'TWO',
    level3: 'THREE',
    level4: 'FOUR',
    level1full: 'Level 1',
    level2full: 'Level 2',
    level3full: 'Level 3',
    level4full: 'Level 4',
    downlineLevel: 'Level',
    no_data: 'No data found',
    banner_text: `We offer you the highest rate of rebate commission to ensure your development. Join DmWin now, enjoy the industry's highest Agent rebate program, and achieve the pinnacle of your career.`,
    contact: 'Contact Us',
    kevin_review: `I have never been an agent before. I found out that DmWin has a strong supporting
    agents policy, so I decided to give it a try. I have been working for a month now and got
    my first commission. I will seize this opportunity and keep up the good work.`,
    charlie_review: `I have been an agent on other platforms for two years. The model of DmWin is very
    novel. This is the first time I have seen it. I plan to bring my downline members here to
    try it this month.`,
    steve_review: `I have been an agent here for several months. DmWin's model is very good. The
    platform is pretty considerate towards our agents. We Agents get to choose which 
    commission options there is for more commission. Its quite fascinating for us who wants
    to earn more commission.`,
    sandy_review: `The agency commission is paid out on the 7th of every month. I would like to give you a
    small suggestion, Sometimes the disbursement might be a bit slow but we will try our
    best to quicken the process.`,
    cindy_review: `The agents here are quite easy to work with. I've taken two months profit commissions
    and now I am working on the wager commission. I look forward for better income.`,
    scott_reivew: `There are quite a lot of games. I have played it for more than a month and the overall
    feeling is pretty smooth and delightful. I have a friend who is already an agent and I am
    going to give it a try.`,
    qna_title: 'Question and Answer',
    q1_title: `What is the Affiliate Program?`,
    q1_content: `The affiliate program is a partnership between you and the operator - DmWin. As part of the affiliate marketing program, the Operator pays you a commission for each referred customer active on the platform.`,
    q2_title: `Is there a fee becoming a DmWin's Agent?`,
    q2_content: `No, there is no cost for joining DmWin's affiliate marketing program. `,
    q3_title: `How do I make money with your program?`,
    q3_content: `When you recommend a player, the player needs to register using the referral link you provided and will be associated with your agent account. Whenever that player places a bet, you will earn a percentage of that player's effective bet. The commission will be distributed promptly at 12 noon the next day.`,
    q4_title: `How can I keep track of my performance?`,
    q4_content: `You can track your performance by logging into the DmWin platform. On the main affiliate dashboard you have all the relevant information for deeper analysis.`,
    commission1:
      'Agent can earn 55% agency commission if downline members generate negative profits.',
    commission1_text: `<ul>
    <li>Number of active members: ≥5</li>
    <li>Income/Profit commission: 55%</li>
    </ul>
    <div>Significant Commission Terms and Conditions:</div>
    <p>
      -For the the first three months, if there are 5 active members per month, the
      commission will be 55%.The commission will continue to be 55% for the next three
      months if the cumulative income/profit reach 300,000 rupees. As long income/profit
      reach 300,000 rupees every 3 months, the 55% commission will be extended for 3
      months.
    </p>
    <p>
      -NOTE when the profit is negative or the active members do not meet the
      requirements, the 55% commission will be canceled hence you can only enjoy basic
      commission.
    </p>
    <p>
      -NOTE: ACTIVE MEMBERS REFER to deposits greater than or equal to 5 times in the
      month, the number of deposits greater than or equal to 1,000, and the betting volume
      greater than or equal to 5000.
    </p>`,
    commission2:
      'Agent can earn commissions of up to 500 rupees per person by adding new downline members.',
    commission2_text: `If you have many friends who are enthusiasts towards online gambling, you can also
    choose to get reward/commission through cumulative of new members 4 Different
    sections for commission rate/ratio together with active members.`,
    commission3:
      'Agent can earn up to 1% of the effective betting wager generated by downline members.',
    commission3_text: `Agent's Promotion Requirements `,
    commission4: 'Effective promotion for DmWin will provide you with wages and bonuses',
    commission4_text: `<p>
    You can create a group or community to send designated advertisements of our
    platform if you have extensive amount of friends regarding facebook, whatsapp,
    yahoo, messenger, instagram etc
    </p>
    <p>
      We will provide you with a promotion fee, this salary is only related to the number
      of people in the group or community you established and the number of times you send
      advertisements.
    </p>
    <p>Please send email to cs@dmwin.org for consultation.</p>`,
    new_members: 'New Members',
    active_members: 'Active Members',
    bet_volume: 'Bet Volume',
    contact_brand: 'Contact DmWin',
    contact_subtitle: 'Send us your questions and we will get back to you within an hour.',
    turnover_tooltip: 'Including to the fourth level of downline',
    amount: 'Amount',
    agent_level: 'Agent Level',
    total_bet: 'Total Bet',
    recharge: 'Recharge',
    commission_instructions_header: 'Agency Programs and Instructions',
    commission_instructions1:
      'DmWin Affiliate Program provides the opportunity to earn revenue by attracting new partners',
    commission_instructions2:
      'You can earn up to 1% commission from the effective bets of your downline members',
    commission_instructions3:
      'His/her game bet amount (regardless win or lose) is eligible for a certain commission percentage.',
    commission_instructions4:
      'To assess the effectiveness of your downline members, detailed statistics are available in your dashboard, filterable by date and downline members',
  },
  bonus: {
    available_for: 'AVAILABLE FOR',
    available_forever: 'AVAILABLE FOREVER!!',
    days: 'Days',
    hours: 'Hours',
    mins: 'Mins',
    secs: 'Secs',
    get_bonus: 'Get Bonus',
    play_now: 'Play Now',
    details: 'Details',
    rebate_promo: 'Rebate Promo',
    game_select: 'Please select game options to proceed with your bonus.',
    spin_now: 'Spin Now',
    more_details: 'More Details',
    spin_won_title: 'YOU WIN',
    spin_btn: 'SPIN',
    promotions: 'Promotions',
    welcome_bonus: 'Welcome',
    deposit: 'Deposit',
    vip: 'VIP',
    others: 'Others',
    permenant: 'Permenant',
    finished_on: 'Ends on ',
    read_more: 'Read More',
    voucher: {
      voucher_enter_code: 'Please enter code',
      successful_redeem: 'Redemption code successfully redeemed',
      failed_redeem: 'Redemption code failed to redeem',
    },
    claim: 'Claim',
    claimed: 'Claimed',
  },
  vip: {
    dmWin: 'DmWin',
    vip: 'VIP',
    benefit: 'BENEFITS',
    info: `Your VIP level at DmWin is upgraded in real-time as you reach the corresponding
    betting amount. Upgrade to Diamond VIP and enjoy DmWin’s customized VIP service.`,
    vip_event: 'VIP-Only Events',
    gift: 'Personalized Gifts',
    bonus: 'Exclusive Bonuses',
    privilege_detail: 'Privilege Details',
    privilege_content: `As a player, if you manage to reach the pinnacle of achievement, you’ll be generously
    rewarded with a bonus ranging from ₹15,000 to ₹500,000. Additionally, you’ll gain access
    to an enhanced level of service and an unparalleled gaming experience that is truly
    one-of-a-kind. Get ready to embark on an exciting journey with DmWin and indulge in
    endless enjoyment!`,
    rebate_bonus: 'Rakeback Bonus',
    bonus_info: `Rakeback Bonus is calculated based on (100% - RTP) * bet amount * 10%, which can be found in the RTP of each game.`,
    daily_bonus: 'Daily Bonus',
    join_vip: 'Join our VIP club to receive exclusive daily bonuses to brighten up your day.',
    bounce_bonus: 'Bounce Back Bonus',
    bounce_info: `Earn rewards based on your wins and bets on DM. Keep playing to have a chance to
    claim generous cashback bonuses the following day.`,
    lvlup_bonus: 'Level Up Bonus',
    lvlup_info:
      'Each promotion success is celebrated with a gift to push you on to the next level.',
    vip_level: 'VIP Level',
    desc1: 'Players who reach the highest level can get a bonus of',
    desc2: ', a premium quality service, and enjoy a superior player experience.',
    privilege1: 'Rakeback Bonus',
    privilege2: 'Bounce Back Bonus',
    privilege3: 'Daily Bonus',
    privilege4: 'Weekly Bonus',
    privilege5: 'Monthly Bonus',
    privilege6: 'Birthday  Bonus',
    privilege7: 'Level Up Bonus',
    privilege8: 'VIP Subscription Bonus',
    privilege9: 'Exclusive-Customer Service',
    privilege10: 'VIP Tour',
    privilege11: 'Fully Customization Experience',
    privilege12: 'Luxury Hotel Suites',
    level0: 'Iron',
    level1: 'Bronze',
    level2: 'Silver',
    level3: 'Gold',
    level4: 'Platinum I',
    level5: 'Platinum II',
    level6: 'Platinum III',
    level7: 'Platinum IV',
    level8: 'Platinum V',
    level9: 'Platinum VI',
    level10: 'Diamond',
    play_game: 'PLAY GAME',
    vip_progress: 'VIP Progress',
    my_benefit: 'My Benefits',
    claim: 'Claim',
    'Rakeback Bonus': 'Rakeback Bonus',
    'Bounce Back Bonus': 'Bounce Back Bonus',
    'Daily Bonus': 'Daily Bonus',
    'Weekly Bonus': 'Weekly Bonus',
    'Monthly Bonus': 'Monthly Bonus',
    'Birthday Bonus': 'Birthday Bonus',
    'Level Up Bonus': 'Level Up Bonus',
    'VIP Subscription Bonus': 'VIP Subscription Bonus',
    congratulations: 'Congratulations',
    bonus_reward: 'Your reward will be credited into your account!',
    prize_unlocked: 'Prize Unlocked',
  },
  maintenance: {
    come_back: `We'll be back soon!`,
    under_maintain: `We're undergoing maintenance between`,
    and: 'and',
    assistant: 'If you need assistance, please contact our Customer Service',
    here: 'here',
    apology: 'We apologise for any inconvenience caused.',
  },
  installation: {
    android: 'Android',
    iOS: 'iOS',
    download: 'DOWNLOAD',
    android_title: 'INSTALLATION ON ANDROID',
    android_content1: '1. Click the "Download" button from your Android device.',
    android_content2:
      '2. When the file is downloaded, click "Install". If the device asks for additional confirmation, then confirm.',
    android_content3: '3. If you have an account on our site, then login, if not, then register.',
    ios_title: 'INSTALLATION ON iOS',
    ios_content1: '1. Open this page in Safari browser.',
    ios_content2: '2. Tap on "Share" icon in the menu at the bottom of your screen.',
    ios_content3:
      '3. Tap "Add to Home Screen" and then finish. Subsequently the application will be successfully installed on your phone.',
  },
  subscribe: {
    unsubscribe_successful_title: 'Unsubscribe Successful',
    unsubscribe_successful_content1:
      'You have been successfully unsubscribed from email communications.',
    unsubscribe_successful_content2:
      'If you did this in error, you may resubscribe by clicking the button below.',
    resubscrible_btn: 'Resubscribe',
  },
  error: {
    notFound_title: 'Something is Wrong',
    notFound_content:
      'You’re out of luck, the page you are looking for was moved, removed, renamed or might never existed.',
    pageError_title: 'An Error Occurred',
    pageError_content:
      'Don’t worry, it’s not you - It’s us. Sorry about that! We’re working on finding a solution as fast as possible.',
  },
  funding: {
    imps: 'BankCard',
    usdt: 'USDT',
  },
  referral_promotion: {
    ads_title: 'GET ₹{rewardAmount} FOR FREE',
    claim: 'Claim',
    remaining_withdraw_amount: 'Refer now to spin {remainingAmount} more to withdraw!',
    full_withdraw_amount: 'Congratulations! You can claim the reward',
    countdown_expiration: 'Countdown to expiration {countdownTime}',
    invite_content: 'Invite friends to help',
    lucky_user: 'Lucky User',
    registration: 'Registration Record',
    rule_content1:
      '1. You will be able to start a withdrawal when the accumulated amount reaches {rewardAmount} Rs.',
    rule_content2:
      '2. Inviting friends to register on dmwin.org will help you withdraw money quickly.',
    rule_content3:
      '3. Each user has a validity period of 7 days for each activity and can participate in the activity multiple times.',
    rule_content4:
      '4. If you receive an extra cash bonus, you can play for free and win even more real money.',
    rule_content5: "5. If you don't withdraw the money within 7 days, it will be lost.",
    rule_content6:
      '6. To avoid differences in textual interpretation, DmWin reserves the right to interpret this event.',
    rule_title: 'Rule',
    no_result: 'No Results',
    successful_withdraw: 'Successful Withdraw',
    username_status: 'Username',
    register_time: 'Registration Time',
    quickWithdrawal: 'Quick Withdrawal',
    congrateText: 'Congratulation on your luck! You’ve won',
    rewardText: 'Received Free ₹{rewardAmount} Reward',
    claimText: 'You have successfully claimed your reward!',
    spinHitCreditWarningText: 'Unable to spin.',
    moreDetailsText: 'More Details',
    inviteStatusInProgress: 'Your members need to complete the deposit to get you spins',
    inviteStatusSuccess: 'Mission Completed',
    spinServerErrorText: 'Server Error, Please try again later',
    inviteBonusInactiveText: 'Invite Bonus Inactive',
    inviteBonusTargetAchievedText: 'Bonus target achieved, pending claim',
    usedUpAllSpinsText: 'User has used up all spin',
  },
  daily_weekly_bonus: {
    tnc: {
      content_daily_1: 'The system will reset rewards at 0:00 daily.',
      content_daily_2:
        'Players who deposit on the same day or within the same week are eligible to participate.',
      content_daily_3: 'Completion of three times of turnover is required before withdrawal.',
      content_daily_4:
        'The use of multiple accounts is prohibited. Any bonuses obtained through participation in this activity or any other violations will be forfeited upon detection of multiple account creation.',
      content_daily_5:
        'DmWin reserves the right to interpret this activity to avoid any textual discrepancies.',
      content_weekly_1: 'The system will reset rewards at 0:00 Monday.',
      content_weekly_2:
        'Players who deposit on the same day or within the same week are eligible to participate.',
      content_weekly_3: 'Completion of three times of turnover is required before withdrawal.',
      content_weekly_4:
        'The use of multiple accounts is prohibited. Any bonuses obtained through participation in this activity or any other violations will be forfeited upon detection of multiple account creation.',
      content_weekly_5:
        'DmWin reserves the right to interpret this activity to avoid any textual discrepancies.',
    },
    criteria_type: {
      profit: 'Profit',
      deposit: 'Deposit',
      betting: 'Betting',
    },
    bonus_type: {
      daily: 'Daily',
      weekly: 'Weekly',
    },
    messages: {
      claim_failed: 'Failed to claim reward!',
      claim_success: 'Successfully claimed reward!',
    },
    todays_profit: "Today's Profit",
    estimated_bonus: 'Estimated Bonus',
    yesterday_bonus: 'Yesterday Bonus',
    claimed: 'Claimed',
    claim: 'Claim',
    updated_daily_at: 'Updated daily at 01:00',
    boost_reward_text: 'Boost your rewards with every deposit!',
    mute_popup: "Don't show this popup again today",
    profit_bonus: 'Profit / Bonus',
    daily_profit: 'Daily Profit',
    bonus: 'Bonus',
    level_desc_deposit:
      'The more you deposit, the higher the bonus! Act now for continuous surprises!',
    level_desc_betting: 'Let your bets be the key to wealth, keep betting and unlock more prizes!',
  },
  daily_bonus: {
    still_cant_claim_free_spin: "Still Can't Claim Your Free Spin?",
    login_claim_free_spin: 'Please Log In Now to Claim Your Free Spin',
    deposit_amt: 'Deposit Amount',
    valid_bet_amt: 'Valid Bet Amount',
    freespin_available: 'Freespin Available',
    claim: 'CLAIM',
    dont_show_today: "Don't show again today",
    today: 'Today',
    day: 'Day',
    details: 'Details',
  },
  deposit_app: {
    reward_loyalty: 'Dear user, as a reward for your loyalty, we are hereby gifting you ',
    claim_reward: 'Please claim your rewards in the APP!',
    claim: 'CLAIM',
    login_acc: 'Please login to your account. Thank you!',
    binding_acc: "Oh, it looks like you haven't finished binding yet",
    email: 'Email',
    mobile: 'Mobile',
    completed_bonus: 'You have completed all bonus.',
    details: 'Details',
    deposit_more_than: 'Deposit more than',
    claim_bonus_today: "to claim today's Bonus!",
    day: 'Day',
    bonus: 'BONUS',
  },
};

export default en;
