import { API } from './api';
const dUrl = API.CDN_REACT + '/desktop';
const mUrl = API.CDN_REACT + '/mobile';
export const DesktopImages = {
  logo: `${API.CDN_REACT}/logo.png`,
  bg: `${dUrl}/bg.png`,
  favIcon: `${dUrl}/love.png`,
  watchIcon: `${dUrl}/eye.png`,
  footer: {
    fb: `${dUrl}/footer/facebok.png`,
    ins: `${dUrl}/footer/ins.png`,
    twitter: `${dUrl}/footer/twitter.png`,
    ytb: `${dUrl}/footer/youtube.png`,
    resp: `${dUrl}/footer/21.png`,
  },
  home: {
    titleIcon: `${dUrl}/home/title-icon.png`,
    arrowBtn: `${dUrl}/home/arrow-btn.png`,
    playIcon: `${dUrl}/home/play.png`,
    streamIcon: `${dUrl}/home/stream.png`,
    liveTab0: `${dUrl}/home/basketball.png`,
    liveTab1: `${dUrl}/home/football.png`,
    liveTab2: `${dUrl}/home/tennis.png`,
    liveTab3: `${dUrl}/home/royale.png`,
  },
  test: {
    model: `${dUrl}/test/model.png`,
    homeimg: `${dUrl}/test/pot.png`,
    awayimg: `${dUrl}/test/nsc.png`,
    recImg: `${dUrl}/test/fish1v1.png`,
    recImg2: `${dUrl}/test/fish2.png`,
    topGame: `${dUrl}/test/topgame.png`,
    promoImg: `${dUrl}/test/promotion.png`,
  },
};

export const MobileImages = {
  welcomeBg: `${mUrl}/welcome_pack.png`,
  logoPrimary: `${API.CDN_REACT}/logo-primary.png`,
  logo: `${API.CDN_REACT}/logo.png`,
  favIcon: `${dUrl}/love.png`,
  watchIcon: `${dUrl}/eye.png`,
  notiSuccess: `${API.CDN_REACT}/noti-success-v2.png`,
  notiError: `${API.CDN_REACT}/noti-error-v2.png`,
  notiWarning: `${API.CDN_REACT}/noti-warning-v2.png`,
  notiInfo: `${API.CDN_REACT}/noti-info-v2.png`,
  logoBlue: `${mUrl}/user/logo-blue.png`,
  coin: `${API.CDN_REACT}/coin.png`,
  wallet: `${API.CDN_REACT}/wallet_icon.png`,
  vipSmall: `${API.CDN_REACT}/vip_small.png`,
  vipBig: `${API.CDN_REACT}/vip_big.png`,
  subscriptionEmailIcon: `${API.CDN_REACT}/unsubscribe-email.png`,
  error_404Icon: `${API.CDN_REACT}/404.png`,
  error_oppsIcon: `${API.CDN_REACT}/error_opps.png`,
  memberDepositBonusBg: `${mUrl}/memberdeposit_bonus.png`,
  common: {
    close: `${mUrl}/common/close.png`,
    closeYellow: `${mUrl}/common/close_yellow.png`,
    modalbg: `${mUrl}/modal-bg.png`,
    banner: `${mUrl}/common/banner.png`,
  },
  footer: {
    appIcon: `${mUrl}/footer/app_icon.svg`,
  },
  home: {
    affiliateIcon: `${mUrl}/home/sidebar/affiliate.svg`,
    msg: `${mUrl}/home/msg.png`,
    casinoIcon: `${mUrl}/home/casino.png`,
    sportsIcon: `${mUrl}/home/sports.png`,
    slotsIcon: `${mUrl}/home/slots.png`,
    casinoImage: `${mUrl}/home/casino-bg.png`,
    sportsImage: `${mUrl}/home/sports-bg.png`,
    slotsImage: `${mUrl}/home/slots-bg.png`,
    hotIcon: `${mUrl}/home/hot.png`,
    arrowIcon: `${mUrl}/home/arrow.png`,
    registerBtnBg: `${mUrl}/home/btn-bg.png`,
    payment: `${mUrl}/home/paymentv2.png`,
    depositBtn: `${mUrl}/home/deposit-btn-v2.png`,
    facebookIcon: `${mUrl}/home/facebook.png`,
    instagramIcon: `${mUrl}/home/instagram.png`,
    youtubeIcon: `${mUrl}/home/youtube.png`,
    twitterIcon: `${mUrl}/home/twitter.png`,
    twitchTvIcon: `${mUrl}/home/twitch_tv.png`,
    telegramIcon: `${mUrl}/home/telegram.png`,
    sidebarBanner: `${mUrl}/home/sidebar_banner.png`,
    cricketBanner: `${mUrl}/home/cricket_banner.png`,
    aviatorBanner: `${mUrl}/home/aviator_banner.png`,
    slotsNavIcon: `${mUrl}/home/sidebar/slot.svg`,
    sportsNavIcon: `${mUrl}/home/sidebar/sport.svg`,
    casinoNavIcon: `${mUrl}/home/sidebar/casino.svg`,
    liveEveIcon: `${mUrl}/home/sidebar/live_event.svg`,
    cricketIcon: `${mUrl}/home/sidebar/cricket.svg`,
    soccerIcon: `${mUrl}/home/sidebar/soccer.svg`,
    hockeyIcon: `${mUrl}/home/sidebar/hockey.svg`,
    badmintonIcon: `${mUrl}/home/sidebar/badminton.svg`,
    wrestlingIcon: `${mUrl}/home/sidebar/wrestling.svg`,
    basketballIcon: `${mUrl}/home/sidebar/basketball.svg`,
    boxingIcon: `${mUrl}/home/sidebar/boxing.svg`,
    tennisIcon: `${mUrl}/home/sidebar/tennis.svg`,
    baseballIcon: `${mUrl}/home/sidebar/baseball.svg`,
    dotaIcon: `${mUrl}/home/sidebar/dota.svg`,
    csgoIcon: `${mUrl}/home/sidebar/csgo.svg`,
    originalIcon: `${mUrl}/home/sidebar/original.svg`,
    pokerIcon: `${mUrl}/home/sidebar/poker.svg`,
    allIcon: `${mUrl}/home/sidebar/all.svg`,
    recentIcon: `${mUrl}/home/sidebar/recent.svg`,
    favouriteIcon: `${mUrl}/home/sidebar/favourite.svg`,
    newReleaseIcon: `${mUrl}/home/sidebar/new_releases.svg`,
    popularIcon: `${mUrl}/home/sidebar/popular.svg`,
    bonusBuyIcon: `${mUrl}/home/sidebar/bonusbuy.svg`,
    blackjackIcon: `${mUrl}/home/sidebar/blackjack.svg`,
    baccaratIcon: `${mUrl}/home/sidebar/baccarat.svg`,
    rouletteIcon: `${mUrl}/home/sidebar/roulette.svg`,
    aviatorIcon: `${mUrl}/home/sidebar/aviator.svg`,
    rummyIcon: `${mUrl}/home/sidebar/rummy.svg`,
    teenpattiIcon: `${mUrl}/home/sidebar/teenpatti.svg`,
    indiaPokerIcon: `${mUrl}/home/sidebar/india_poker.svg`,
    lotteryNavIcon: `${mUrl}/home/sidebar/lottery_nav_icon.svg`,
    bonusIcon: `${mUrl}/home/sidebar/bonus.svg`,
    vipIcon: `${mUrl}/home/sidebar/vip.svg`,
    livesupporttIcon: `${mUrl}/home/sidebar/livesupport.svg`,
    homePopular: `${mUrl}/home/icon-popular.png`,
    homeSlot: `${mUrl}/home/icon-slots.png`,
    homeCasino: `${mUrl}/home/icon-casino.png`,
    homeLottery: `${mUrl}/home/lotteryIcon.png`,
    cricketEn: `${mUrl}/home/cricket_en.png`,
    cricketHindi: `${mUrl}/home/cricket_hindi.png`,
    sportsColorIcon: `${mUrl}/home/sports-color.png`,
    appDownload: `${mUrl}/home/app-download.png`,
    androidDownloadBtn: `${mUrl}/home/android-download.png`,
    iosDownloadBtn: `${mUrl}/home/ios-download.png`,
    iosAddToHomeImage: `${mUrl}/home/ios-add-to-home.png`,
    iosShareImage: `${mUrl}/home/ios-share.png`,
    downloadBg: `${mUrl}/home/sidebar/download_bg.png`,
    providersIcon: `${mUrl}/home/sidebar/providers.svg`,
    providersHomeIcon: `${mUrl}/home/providersHomeIcon.png`,
    spinCountBg: `${mUrl}/home/sidebar/spin_count_bg.svg`,
    spinImg: `${mUrl}/home/spin_img.png`,
    betsIcon: `${mUrl}/home/betsIcon.png`,
    rupeeIcon: `${mUrl}/home/rupee.png`,
    prizeWonBg: `${mUrl}/luckyWheel/win_bg.png`,
    spinPrizeWonFooter: `${mUrl}/luckyWheel/winner_footer.png`,
    spinPrizeWonHeader: `${mUrl}/luckyWheel/winner_header.png`,
    prizeWonDiv: `${mUrl}/luckyWheel/txt_div.png`,
    welcomeBonusIcon: `${mUrl}/home/welcome_bonus_icon.png`,
    joinNowIcon: `${mUrl}/home/join_now_icon.png`,
    rupeeYellowIcon: `${mUrl}/home/rupee_yellow.png`,
    rupeeGreenIcon: `${mUrl}/home/rupee_green.png`,
    claimBtnBg: `${mUrl}/home/claim_btn_bg.png`,
    claimBtnBgDisabled: `${mUrl}/home/claim_btn_bg_disabled.png`,
    shareIcon: `${mUrl}/home/share.svg`,
    noResult: `${mUrl}/home/no_result.png`,
    scrollTop: `${mUrl}/home/scroll_top.png`,
    sysAnnouncementBg: `${mUrl}/home/sys_announcement_bg.png`,
    sysAnnouncementIcon: `${mUrl}/home/sys_ann_icon.png`,
    shortcutDefaultImg: `${mUrl}/home/shortcut_default.png`,
  },
  profile: {
    editIcon: `${mUrl}/user/edit.png`,
    dateBg: `${mUrl}/user/datemodal-bg.png`,
  },
  deposit: {
    activeBg: `${mUrl}/deposit/active-bg.png`,
    bonusBg: `${mUrl}/deposit/bonus-bg.png`,
    iosDepositPopup: `${mUrl}/deposit/deposit-iospopup.png`,
    depositSubmit: `${mUrl}/deposit/deposit-submit.png`,
  },
  slots: {
    noData: `${mUrl}/game/no-result.png`,
  },
  vip: {
    bg1: `${mUrl}/vip/vip-bg1.png`,
    diamond1: `${mUrl}/vip/diamond1.png`,
    diamond2: `${mUrl}/vip/diamond2.png`,
    gift: `${mUrl}/vip/gift.png`,
    bonus: `${mUrl}/vip/bonus.png`,
    privilege1: `${mUrl}/vip/privilege1.png`,
    privilege2: `${mUrl}/vip/privilege2.png`,
    privilege3: `${mUrl}/vip/privilege3.png`,
    privilege4: `${mUrl}/vip/privilege4.png`,
    iron: `${API.CDN_REACT}/shared/vip/iron-v2.png`,
    bronze: `${API.CDN_REACT}/shared/vip/bronze-v2.png`,
    silver: `${API.CDN_REACT}/shared/vip/silver-v2.png`,
    gold: `${API.CDN_REACT}/shared/vip/gold-v2.png`,
    platinum1: `${API.CDN_REACT}/shared/vip/platinum1-v2.png`,
    platinum2: `${API.CDN_REACT}/shared/vip/platinum2-v2.png`,
    platinum3: `${API.CDN_REACT}/shared/vip/platinum3-v2.png`,
    platinum4: `${API.CDN_REACT}/shared/vip/platinum4-v2.png`,
    platinum5: `${API.CDN_REACT}/shared/vip/platinum5-v2.png`,
    platinum6: `${API.CDN_REACT}/shared/vip/platinum6-v2.png`,
    diamond: `${API.CDN_REACT}/shared/vip/diamond-v2.png`,
    ironSmall: `${API.CDN_REACT}/shared/vip/iron-small.png`,
    bronzeSmall: `${API.CDN_REACT}/shared/vip/bronze-small.png`,
    silverSmall: `${API.CDN_REACT}/shared/vip/silver-small.png`,
    goldSmall: `${API.CDN_REACT}/shared/vip/gold-small.png`,
    platinum1Small: `${API.CDN_REACT}/shared/vip/platinum1-small.png`,
    platinum2Small: `${API.CDN_REACT}/shared/vip/platinum2-small.png`,
    platinum3Small: `${API.CDN_REACT}/shared/vip/platinum3-small.png`,
    platinum4Small: `${API.CDN_REACT}/shared/vip/platinum4-small.png`,
    platinum5Small: `${API.CDN_REACT}/shared/vip/platinum5-small.png`,
    platinum6Small: `${API.CDN_REACT}/shared/vip/platinum6-small.png`,
    diamondSmall: `${API.CDN_REACT}/shared/vip/diamond-small.png`,
    bg2: `${mUrl}/vip/vip-bg2.png`,
    progressBg: `${mUrl}/vip/progress-bg.png`,
    arrow: `${mUrl}/vip/vip-arrow.png`,
    claimBg: `${mUrl}/vip/claim-bg.png`,
    popupGift: `${mUrl}/vip/popup-gift.png`,
    bonusClaimBg: `${mUrl}/vip/bonus-claim-bg.png`,
    bonusCoin: `${mUrl}/vip/bonus_coin.png`,
  },
  test: {
    model: `${dUrl}/test/model.png`,
    homeimg: `${dUrl}/test/pot.png`,
    awayimg: `${dUrl}/test/nsc.png`,
    recImg: `${dUrl}/test/fish1v1.png`,
    recImg2: `${dUrl}/test/fish2.png`,
    topGame: `${dUrl}/test/topgame.png`,
    topGame2: `${dUrl}/test/top2.png`,
    promoImg: `${dUrl}/test/promotion.png`,
    gameImg1: `${dUrl}/test/game1.png`,
    gameImg2: `${dUrl}/test/game2.png`,
    gameImg3: `${dUrl}/test/game3.png`,
    gameImg4: `${dUrl}/test/game4.png`,
    gameImg5: `${dUrl}/test/game5.png`,
    gameImg6: `${dUrl}/test/game6.png`,
    gameImg7: `${dUrl}/test/game7.png`,
    gameImg8: `${dUrl}/test/game8.png`,
    gameImg9: `${dUrl}/test/game9.png`,
    gameImg10: `${dUrl}/test/game10.png`,
    gameImg11: `${dUrl}/test/game11.png`,
    gameImg12: `${dUrl}/test/game12.png`,
    gameImg: `${dUrl}/test/gamev4.png`,
    depositIcon: `${dUrl}/test/deposit.png`,
    bonusBg: `${dUrl}/test/testbonus-bg.png`,
  },
  login: {
    logo: `${mUrl}/login/logo-white.png`,
    bg: `${mUrl}/login/bg.png`,
    google: `${mUrl}/login/google.png`,
    facebook: `${mUrl}/login/facebook.png`,
    telegram: `${mUrl}/login/telegram.png`,
    popupHeader: `${mUrl}/login/popup-header.png`,
  },
  register: {
    banner: `${mUrl}/register/banner.png`,
    captchaRefresh: `${mUrl}/register/captcha-refresh.png`,
  },
  bonus: {
    slots: `${mUrl}/bonus/slots.png`,
    casino: `${mUrl}/bonus/casino.png`,
    sports: `${mUrl}/bonus/sports.png`,
    original: `${mUrl}/bonus/original.png`,
    poker: `${mUrl}/bonus/poker.png`,
    readmore_btn: `${mUrl}/bonus/readmore_btn.png`,
    welcomeBonus: `${mUrl}/bonus/welcome_bonus.png`,
    iconMinus: `${mUrl}/bonus/icon_condense.svg`,
    iconGift: `${mUrl}/bonus/icon_gift.svg`,
  },
  maintenance: {
    bg: `${mUrl}/maintenance.png`,
  },
  affiliate: {
    commission: `${mUrl}/affiliate/commission.png`,
    graph: `${mUrl}/affiliate/graph.png`,
    upArrow: `${mUrl}/affiliate/circle-up.png`,
    downArrow: `${mUrl}/affiliate/circle-down.png`,
    partner: `${mUrl}/affiliate/partner.png`,
    whatsapp: `${mUrl}/affiliate/whatsapp.png`,
    telegram: `${mUrl}/affiliate/telegram.png`,
    wechat: `${mUrl}/affiliate/wechat.png`,
    facebook: `${mUrl}/affiliate/facebook.png`,
    instagram: `${mUrl}/affiliate/ins.png`,
    twitter: `${mUrl}/affiliate/twitter.png`,
    skype: `${mUrl}/affiliate/skype.png`,
    gmail: `${mUrl}/affiliate/gmail.png`,
    model: `${mUrl}/affiliate/agent-modelv3.png`,
    commissionBanner: `${mUrl}/affiliate/commission-banner.png`,
    commissionBanner1: `${mUrl}/affiliate/commission-banner1.png`,
    commissionBanner2: `${mUrl}/affiliate/commission-banner2.png`,
    commissionBanner3: `${mUrl}/affiliate/commission-banner3.png`,
    commissionBanner4: `${mUrl}/affiliate/commission-banner4.png`,
    btnBlue: `${mUrl}/affiliate/btn-blue.png`,
    btnPink: `${mUrl}/affiliate/btn-pink.png`,
    btnPurple: `${mUrl}/affiliate/btn-purple.png`,
    bePartner: `${mUrl}/affiliate/bepartner.png`,
    reviewBg: `${mUrl}/affiliate/review-bg.png`,
    comma: `${mUrl}/affiliate/comma.png`,
    star: `${mUrl}/affiliate/star.png`,
    btnBlue2: `${mUrl}/affiliate/btn-blue-2.png`,
    btnOrange: `${mUrl}/affiliate/btn-orange.png`,
  },
  freespin: {
    freespin_btn: `${mUrl}/freespin/freespin_btn.svg`,
    freespin_cross: `${mUrl}/freespin/freespin_cross.svg`,
    freespin: `${mUrl}/freespin/freespins.png`,
    freespin_download_btn: `${mUrl}/freespin/freespin_download_btn.svg`,
    freespin_bubble: `${mUrl}/freespin/freespin_bubble.svg`,
    success: `${mUrl}/freespin/success.svg`,
    nonsuccess: `${mUrl}/freespin/nonsuccess.svg`,
    playnow_btn: `${mUrl}/freespin/playnow_btn.svg`,
    account_btn: `${mUrl}/freespin/account_btn.svg`,
  },
  withdraw: {
    usdt_icon: `${mUrl}/withdraw/USDT.png`,
  },
  vault: {
    password_icon: `${mUrl}/vault/success.png`,
  },
  referral_promotion: {
    claimBtnBg: `${mUrl}/home/claim_btn_bg.png`,
    referralWheelBanner: `${mUrl}/referralPromotion/referral_wheel_banner.png`,
    whatsapp: `${mUrl}/referralPromotion/whatsapp.png`,
    telegram: `${mUrl}/referralPromotion/telegram.png`,
    facebook: `${mUrl}/referralPromotion/facebook.png`,
    instagram: `${mUrl}/referralPromotion/instagram.png`,
    twitter: `${mUrl}/referralPromotion/twitter.png`,
    referralWheelBg: `${mUrl}/referralPromotion/referralwheel_bg.png`,
    referralWheelDeco: `${mUrl}/referralPromotion/referralwheel_deco.png`,
    getMoreBtn: `${mUrl}/referralPromotion/get_more_btn.png`,
    referralSpin: `${mUrl}/referralPromotion/referral_spin.gif`,
  },
  daily_login_bonus: {
    titleBg: `${mUrl}/dailybonus/title_bg.png`,
    detailsFrame: `${mUrl}/dailybonus/details_frame.png`,
    claimBg: `${mUrl}/dailybonus/claim_bg.png`,
    freeSpinFrame: `${mUrl}/dailybonus/free_spin_frame.png`,
    claimBgOpacity: `${mUrl}/dailybonus/claim_bg_opacity.png`,
    bottomFrame: `${mUrl}/dailybonus/bottom_frame.png`,
    claimBtn: `${mUrl}/dailybonus/claim_btn.png`,
    claimBgNon: `${mUrl}/dailybonus/claim_bg_non.png`,
    claimed: `${mUrl}/dailybonus/claimed.png`,
    floatBtn: `${mUrl}/dailybonus/check-in.gif`,
    prevIcon: `${mUrl}/dailybonus/prev_icon.png`,
    nextIcon: `${mUrl}/dailybonus/next_icon.png`,
    lauchGameIcon: `${mUrl}/dailybonus/lauch_game_icon.png`,
  },
  daily_weekly_bonus: {
    banner: `${mUrl}/dailyWeeklyBonus/banner.png`,
    menuItem: `${mUrl}/dailyWeeklyBonus/menu_item.png`,
    menuItemActive: `${mUrl}/dailyWeeklyBonus/menu_item_active.png`,
    icon_info: `${mUrl}/dailyWeeklyBonus/icon_info.png`,
    bonus_coin: `${mUrl}/dailyWeeklyBonus/bonus_coin.png`,
    r_coin: `${mUrl}/dailyWeeklyBonus/r_coin.png`,
    icon_claimed: `${mUrl}/dailyWeeklyBonus/icon_claimed.png`,
    floating_btn: `${mUrl}/dailyWeeklyBonus/daily-win.gif`,
    icon_tick: `${mUrl}/dailyWeeklyBonus/icon_tick.png`,
  },
  deposit_app_bonus: {
    floatBtn: `${mUrl}/depositAppBonus/download-bonus.gif`,
    titleBg: `${mUrl}/depositAppBonus/title_bg.png`,
    bg: `${mUrl}/depositAppBonus/bg.png`,
    bottomFrame: `${mUrl}/depositAppBonus/bottom_frame.png`,
    claimBtn: `${mUrl}/depositAppBonus/claim_btn.png`,
    claimBg: `${mUrl}/depositAppBonus/claim_bg.png`,
    claimBgOpacity: `${mUrl}/depositAppBonus/claim_bg_opacity.png`,
    claimed: `${mUrl}/depositAppBonus/claimed.png`,
    prevIcon: `${mUrl}/depositAppBonus/prev_icon.png`,
    nextIcon: `${mUrl}/depositAppBonus/next_icon.png`,
    claimBtnIcon: `${mUrl}/depositAppBonus/claim_btn_icon.png`,
    bonusCoin: `${mUrl}/depositAppBonus/bonus_coin.png`,
  },
};
