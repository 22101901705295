import { ACTIONS } from '../constants/actions';

export const setVerifyCountDownTimer = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COUNTDOWN.VERIFY_COUNTDOWN_TIMER,
  });

export const setRemoveTimer = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COUNTDOWN.REMOVED_TIMER,
  });

export const setTimer = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COUNTDOWN.TIMER,
  });

export const setIsCountdown = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.COUNTDOWN.IS_COUNTDOWN,
  });
